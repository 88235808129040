import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';

// Initial state
const initialState = {
    isAuthenticated: false,
    currentUser: null,
    userData: null,
    generatedRecipes: [],
    preferences: {
        servings: 2,
        budget: 100,
        diet: 'no',
        includeIngredients: [],
        avoidIngredients: [],
        meals: {
            breakfast: 2,
            main: 4,
            snacks: 1,
            salads: 0,
            sweets: 0
        },
    },
    previousCombinations: [],
    currentCombinationIndex: null,
    allRecipes: [], // Add allRecipes to the initial state
    token: null,
};

// Create the context
const AppStateContext = createContext();

// Create the context provider
export const AppStateProvider = ({ children }) => {
    const [state, setState] = useState(() => {
        const storedState = localStorage.getItem('appState');
        return storedState ? JSON.parse(storedState) : initialState;
    });

    useEffect(() => {
        const storedState = localStorage.getItem('appState');
        const currentState = JSON.stringify(state);

        // Compare the current state with the stored state
        if (currentState !== storedState) {
            localStorage.setItem('appState', currentState);
        }
    }, [state]);


    const setAuthState = (isAuthenticated, currentUser, token) => {
        setState((prevState) => ({
            ...prevState,
            isAuthenticated,
            currentUser,
            token,
        }));
    };

    const setInitialState = () => {
        setState((prevState) => ({
            ...prevState,
            isAuthenticated: false,
            currentUser: null,
            userData: null,
            generatedRecipes: [],
            preferences: {
                servings: 2,
                budget: 100,
                diet: 'no',
                includeIngredients: [],
                avoidIngredients: [],
                meals: {
                    breakfast: 2,
                    main: 2,
                    snacks: 1,
                    salads: 1,
                    sweets: 1
                },
            },
            previousCombinations: [],
            currentCombinationIndex: null,
            token: null,
        }))
    };

    const setCurrentWeekGeneratedCombination = (generatedRecipes) => {
        setState((prevState) => ({
            ...prevState,
            generatedRecipes
        }));
    };

    const setUserData = (userData) => {
        setState((prevState) => ({
            ...prevState,
            userData,
        }));
    };

    const setCurrentUser = (currentUser) => {
        setState((prevState) => ({
            ...prevState,
            currentUser,
        }));
    };

    const setAllRecipes = (allRecipes) => {
        setState((prevState) => ({
            ...prevState,
            allRecipes,
        }));
    };

    const setPreviousCombinations = (previousCombinations) => {
        setState((prevState) => ({
            ...prevState,
            previousCombinations,
        }));
    };

    const setCurrentCombinationIndex = (index) => {
        setState((prevState) => ({
            ...prevState,
            index,
        }));
    };

    const setPreferences = (preferences) => {
        setState((prevState) => ({
            ...prevState,
            preferences,
        }));
    };

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                user.getIdToken().then((token) => {
                    setAuthState(true, user, token);
                    fetchUserData(user.uid, token);
                });
            } else {
                setAuthState(false, null, null);
            }
        });

        return () => unsubscribe();
    }, []);

    const fetchUserData = async (userId, token) => {
        try {
            console.log('Fetching user data...');
            const response = await axios.get(`https://us-central1-the-weekly-meals.cloudfunctions.net/api/user/data`, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });
            console.log('User data fetched successfully:', response.data);
            setUserData(response.data);
        } catch (error) {
            console.error('Error fetching user data:', error);
            setUserData(null);
        }
    };


    const fetchAllRecipes = async () => {
        try {
            const response = await axios.get('https://us-central1-the-weekly-meals.cloudfunctions.net/api/recipes');
            setAllRecipes(response.data);
        } catch (error) {
            console.error('Error fetching all recipes:', error);
            setAllRecipes([]);
        }
    };

    useEffect(() => {
        fetchAllRecipes();
    }, []);

    return (
        <AppStateContext.Provider value={{ state, setAuthState, setUserData, setAllRecipes, setPreferences, setCurrentWeekGeneratedCombination, setInitialState, setCurrentUser, setPreviousCombinations, setCurrentCombinationIndex }}>
            {children}
        </AppStateContext.Provider>
    );
};

// Custom hook to use the context
export const useAppState = () => {
    const context = useContext(AppStateContext);
    if (!context) {
        throw new Error('useAppState must be used within an AppStateProvider');
    }
    return context;
};

// Listen for auth change and set the initial state
export const AuthListener = () => {
    const { setAuthState, setUserData } = useAppState();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            try {
                if (user) {
                    const token = await user.getIdToken();
                    setAuthState(true, user, token);
                    await fetchUserData(user.uid, token);
                } else {
                    setAuthState(false, null, null);
                }
            } catch (error) {
                console.error('Error in onAuthStateChanged', error);
            }
        });

        return () => unsubscribe();
    }, []);


    const fetchUserData = async (userId, token) => {
        console.log('userID' + userId + 'token' + token)

        try {
            const response = await axios.get(`https://us-central1-the-weekly-meals.cloudfunctions.net/api/user/data`, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });

            setUserData(response.data);
        } catch (error) {
            console.error('Error fetching user data', error);
            setUserData(null);
        }
    };

    return null;
};
