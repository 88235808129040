import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAppState } from '../../context/AppStateContext';

export default function GroceryList({ groceryListPerAisle, setGroceryListPerAisle, combination, weekID }) {
    const [totalItemsToBuy, setTotalItemsToBuy] = useState(0);
    const [customItemInput, setCustomItemInput] = useState('');
    const { state } = useAppState();
    const { currentUser, token } = state;
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (groceryListPerAisle) {
            let count = 0;
            Object.values(groceryListPerAisle)?.forEach(aisle => {
                count += aisle.filter(ingredient => ingredient.name && !ingredient.isCrossedOut).length;
            });
            setTotalItemsToBuy(count);
        }
    }, [groceryListPerAisle]);

    const toggleIngredient = (aisle, item) => {
        const updatedGroceryList = { ...groceryListPerAisle };
        updatedGroceryList[aisle] = updatedGroceryList[aisle].map(ingredient => {
            if (ingredient.name === item) {
                return { ...ingredient, isCrossedOut: !ingredient.isCrossedOut };
            }
            return ingredient;
        });
        setGroceryListPerAisle(updatedGroceryList);
    };

    const handleCustomInputChange = (event) => {
        setCustomItemInput(event.target.value);
    };

    const handleAddCustomItem = () => {
        const customItem = customItemInput.trim();
        if (customItem) {
            const updatedGroceryList = { ...groceryListPerAisle };
            const customAisle = "Custom Items";

            if (!updatedGroceryList[customAisle]) {
                updatedGroceryList[customAisle] = [];
            }

            const existingItemIndex = updatedGroceryList[customAisle].findIndex(item => item.name === customItem);
            if (existingItemIndex === -1) {
                updatedGroceryList[customAisle].push({
                    amount: "",
                    unit: "",
                    name: customItem,
                    isCrossedOut: false
                });
                setTotalItemsToBuy(totalItemsToBuy + 1);
            }

            setGroceryListPerAisle(updatedGroceryList);
            setCustomItemInput('');
        }
    };

    const handleDoneClick = async () => {
        setIsLoading(true);

        const requestData = {
            userId: currentUser.uid,
            groceryListPerAisle: groceryListPerAisle,
            weekID: weekID,
            combination: combination
        };

        try {
            await axios.post('https://us-central1-the-weekly-meals.cloudfunctions.net/api/updateGroceryList', requestData, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });
            console.log('User grocery list updated successfully');

            try {
                const response = await fetch('https://us-central1-the-weekly-meals.cloudfunctions.net/api/send-pdf-email', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email: currentUser.email, recipes: state.generatedRecipes }),
                });
                console.log('PDF sent successfully');
            } catch (error) {
                console.error('Error sending PDF by email:', error);
            }

            // Navigate only after both requests are successful
            navigate("/meal-planner/success");
        } catch (error) {
            console.error('Failed to update user grocery list:', error);
            setIsLoading(false); // Stop loading spinner in case of error
        }
    };

    return (
        <>
            {currentUser ? (
                <div className="grocery-list">
                    <h2>Grocery List</h2>
                    <ul>
                        {groceryListPerAisle ? Object.keys(groceryListPerAisle).map((aisle, index) => (
                            <div key={index}>
                                <ul>
                                    {groceryListPerAisle[aisle]
                                        .filter(({ name }) => name)
                                        .map(({ amount, unit, name, isCrossedOut }, itemIndex) => (
                                            <li
                                                key={itemIndex}
                                            >
                                                {name} {amount > 0 && <span className='measurement'>{amount && amount} {unit && unit}</span>}
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        )) : 'Loading grocery list...'}
                    </ul>
                </div>
            ) : (
                <div>
                    <p>You need to log in to access the grocery list.</p>
                    <button className="secondary">Log in</button>
                </div>
            )}
            {
                currentUser && (
                    <div className="next-step">
                        <button 
                            className="done-button" 
                            onClick={handleDoneClick} 
                            disabled={isLoading}
                        >
                            {isLoading ? <div className="spinner"></div> : 'Done'}
                        </button>
                    </div>
                )
            }
        </>
    );
}
