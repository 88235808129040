import React, { useState, useEffect, Suspense, lazy } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import '../styles/Home.css';

// Lazy loading components
const ScrollText = lazy(() => import('../components/ScrollText'));
const Footer = lazy(() => import('../components/Footer'));
const Faq = lazy(() => import('react-faq-component'));

// Data for FAQ
const FAQData = {
  rows: [
    {
      title: `How does The Weekly Plate work?`,
      content: `It integrates with your blog and generates custom meal plans based on your content and user food preferences.`,
    },
    {
      title: "Is it difficult to set up?",
      content: 'No, the integration is quick and easy. You only need to add your custom link on your blog.',
    },
    {
      title: "How much do I have to sell the meal plan?",
      content: "You set the price! Offer this service to your readers at a rate that works for you.",
    },
  ],
};

const FAQStyles = {
  titleTextColor: "black",
  rowTitleColor: "black",
};

const FAQConfig = {
  expandIcon: " ",
  collapseIcon: " ",
};

// Step component for "How it works" section
const Step = ({ number, title, description }) => (
  <div className="step">
    <div className="step-number">{number}</div>
    <div className="step-details">
      <h3 className="step-title">{title}</h3>
      <p className="step-description">{description}</p>
    </div>
  </div>
);

// Button component for reusability
const Button = ({ children, to, primary }) => (
  <Link className={primary ? 'primary' : 'secondary'} to={to}>
    {children}
  </Link>
);

const ReceiveItem = ({ imgSrc, title, description }) => (
  <div className="receive-item">
    <img loading="lazy" src={imgSrc} alt={title} />
    <h3>{title}</h3>
    <p>{description}</p>
  </div>
);

export default function Home() {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Helmet>
        <title>The Weekly Plate</title>
        <meta name="description" content="A white-label service that generates custom meal plans for food blogs." />
      </Helmet>

      <div className="home">
        {/* Hero Section */}
        <section className="hero">
          <div className="container">
            <div className="text">
              <h1>Revolutionize Your Food Blog with AI-Generated Meal Plans</h1>
              <p>Start offering personalized, AI-powered meal plans using your recipes to your readers and unlock new revenue streams.</p>
              <Button primary to='https://calendly.com/thedonovansconsultinggroupllc/1-hour-consulting-call'>Get Started for Free</Button>
            </div>
          </div>
        </section>

        {/* Scroll Text */}
        <Suspense fallback={<div>Loading...</div>}>
          <ScrollText />
        </Suspense>

        {/* Steps Section */}
        <section className="steps-container">
          <h2>How It Works in 3 Simple Steps</h2>
          <div className="steps-grid">
            <Step
              number="1"
              title="Quick Setup"
              description="Integrate our link into your blog and start offering meal plans."
            />
            <Step
              number="2"
              title="AI-Powered Meal Plans"
              description="Our AI generates weekly personalized meal plans and downloadable PDFs for your readers."
            />
            <Step
              number="3"
              title="Earn & Engage"
              description="Offer your readers premium content and generate recurring revenue."
            />
          </div>
          <Button to='https://calendly.com/thedonovansconsultinggroupllc/1-hour-consulting-call' primary>Book a Demo</Button>
        </section>

        {/* What You'll Receive by Email Section */}
        <section className="receive-section">
          <h2>What Your Users Will Receive</h2>
          <p className="subtitle">Your users will receive a PDF via email and have access to their meal plan on The Weekly Plate platform.</p>
          {isDesktop ? (
            <div className="receive-grid">
              <ReceiveItem
                imgSrc={require('../images/Menu.png')}
                title="Weekly Menus"
                description="Complete weekly menus customized to user preferences."
              />
              <ReceiveItem
                imgSrc={require('../images/GroceryList.png')}
                title="Grocery List"
                description="Essential ingredients for your weekly meals."
              />
              <ReceiveItem
                imgSrc={require('../images/RecipeDetails.png')}
                title="Full Recipes"
                description="Step-by-step recipes with ingredient lists."
              />
            </div>
          ) : (
            <Swiper
              modules={[Pagination]}
              spaceBetween={50}
              slidesPerView={1}
              pagination={{ clickable: true }}
            >
              <SwiperSlide>
                <ReceiveItem
                  imgSrc={require('../images/Menu.png')}
                  title="Weekly Menus"
                  description="Complete weekly menus customized to user preferences."
                />
              </SwiperSlide>
              <SwiperSlide>
                <ReceiveItem
                  imgSrc={require('../images/GroceryList.png')}
                  title="Grocery List"
                  description="Essential ingredients for your weekly meals."
                />
              </SwiperSlide>
              <SwiperSlide>
                <ReceiveItem
                  imgSrc={require('../images/RecipeDetails.png')}
                  title="Full Recipes"
                  description="Step-by-step recipes with ingredient lists."
                />
              </SwiperSlide>
            </Swiper>
          )}
        </section>

        {/* Promo Section */}
        <section className="promo-section">
          <div className="container">
            <h2>Unlock New Revenue Streams with Your Recipes</h2>
            <div>
              <p>Turn your recipes into custom meal plans for your audience and boost your revenue effortlessly.</p>
              <Button primary to='https://calendly.com/thedonovansconsultinggroupllc/1-hour-consulting-call'>Schedule a Call</Button>
            </div>
          </div>
        </section>

        {/* USP Section */}
        <section className="usps">
          <h2>Discover the Benefits</h2>
          {isDesktop ? (
            <div className="usp-container">
              <div className="usp-list">
                <div className="usp">
                  <span>01</span>
                  <div>
                    <h4>Boost Your Monetization</h4>
                    <p>Offer exclusive meal plans using your recipes and create a new revenue stream.</p>
                  </div>
                </div>
                <div className="usp">
                  <span>02</span>
                  <div>
                    <h4>Enhance Audience Retention</h4>
                    <p>Keep your readers coming back with personalized meal plans.</p>
                  </div>
                </div>
                <div className="usp">
                  <span>03</span>
                  <div>
                    <h4>Strengthen Your Brand</h4>
                    <p>Offer unique, high-quality meal plans that align with your brand.</p>
                  </div>
                </div>
              </div>
              <div className="img">
                <img
                  loading="lazy"
                  src="https://i.postimg.cc/HsH2gMPS/usps.jpg"
                  width="300"
                  height="auto"
                  alt="Benefits"
                />
              </div>
              <div className="usp-list">
                <div className="usp">
                  <span>04</span>
                  <div>
                    <h4>Promote Healthier Eating</h4>
                    <p>Encourage healthy habits with nutritious meal plans.</p>
                  </div>
                </div>
                <div className="usp">
                  <span>05</span>
                  <div>
                    <h4>Save Your Audience Money</h4>
                    <p>Offer an affordable alternative to meal delivery services.</p>
                  </div>
                </div>
                <div className="usp">
                  <span>06</span>
                  <div>
                    <h4>AI-Generated Plans</h4>
                    <p>Personalized meal plans powered by AI.</p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Swiper
            modules={[Pagination]}
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ clickable: true }}
            loop={true}
          >
            {/* Mobile Slider view */}
            <SwiperSlide>
              <div className="usp">
                <span>01</span>
                <div>
                  <h4>Boost Your Monetization</h4>
                  <p>Offer exclusive meal plans using your recipes and create a new revenue stream.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="usp">
                <span>02</span>
                <div>
                  <h4>Enhance Audience Retention</h4>
                  <p>Keep your readers coming back with personalized meal plans.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide> 
              <div className="usp">
                <span>03</span>
                <div>
                  <h4>Strengthen Your Brand</h4>
                  <p>Offer unique, high-quality meal plans that align with your brand.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide> 
              <div className="usp">
                <span>04</span>
                <div>
                  <h4>Promote Healthier Eating</h4>
                  <p>Encourage healthy habits with nutritious meal plans.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="usp">
                <span>05</span>
                <div>
                  <h4>Save Your Audience Money</h4>
                  <p>Offer an affordable alternative to meal delivery services.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="usp">
                <span>06</span>
                <div>
                  <h4>AI-Generated Plans</h4>
                  <p>Personalized meal plans powered by AI.</p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          )}
        </section>

        {/* FAQ Section */}
        <section className="faq">
          <h2>FAQ</h2>
          <Suspense fallback={<div>Loading FAQ...</div>}>
            <Faq
              data={FAQData}
              styles={FAQStyles}
              config={FAQConfig}
            />
          </Suspense>
        </section>

        {/* Signup Section */}
        <section className="sign-up">
          <h2>Start Offering Custom Meal Plans Today!</h2>
          <p>Monetize your blog with ease by offering personalized meal plans to your readers.</p>
          <Button primary to='https://calendly.com/thedonovansconsultinggroupllc/1-hour-consulting-call'>Get Started for Free</Button>
        </section>

        {/* Footer */}
        <Suspense fallback={<div>Loading Footer...</div>}>
          <Footer />
        </Suspense>
      </div>
    </>
  );
}
