// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDfmYKkKuzdL-9DTZBVLvG4Hg5Q-g0ShsQ",
  authDomain: "the-weekly-meals.firebaseapp.com",
  projectId: "the-weekly-meals",
  storageBucket: "the-weekly-meals.appspot.com",
  messagingSenderId: "876545881815",
  appId: "1:876545881815:web:e311d8d72699c211bce292",
  measurementId: "G-R8H0JXJBZR"
};
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);

// const firestore = getFirestore(app);

export { auth, db, analytics };