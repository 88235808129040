import React, { useEffect } from 'react';
import RecipeCard from '../RecipeCard.jsx';
import { Link } from 'react-router-dom';
import Loader from '../Loaders.jsx';

export default function RecipeSelection({ recipesByCategory, loading, explanations, regenerateCategory, categoryLoading, getRecipesWithPreferences }) {
  function isObjectNotEmpty(obj) {
    return obj && Object.keys(obj).length > 0;
  }

  useEffect(() => {
    console.log(recipesByCategory)

    if (isObjectNotEmpty(recipesByCategory)) {
      return;
    } else {
      getRecipesWithPreferences();

    }
  }, [recipesByCategory]);

  return (
    <div className="recipe-selection">
      {loading ? (
         <Loader />
      ) : (
        <div>
          <div className="recipes">
            {Object.keys(recipesByCategory)
              .filter(category => Array.isArray(recipesByCategory[category]) && recipesByCategory[category].length > 0)
              .map((category) => (
                <div key={category} className={`course ${category}`}>
                  <div className="category-header">
                    <h2 className="category-title">{category.charAt(0).toUpperCase() + category.slice(1)}</h2>
                    <span className="line"></span>
                    <button className={`regenerate ${category}`} onClick={() => regenerateCategory(category)}>
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#C4C4C4" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-refresh-cw">
                        <polyline points="23 4 23 10 17 10"></polyline>
                        <polyline points="1 20 1 14 7 14"></polyline>
                        <path d="M3.51 9a9 9 0 0114.136-4.361l1.356 1.517"></path>
                        <path d="M20.49 15a9 9 0 01-14.136 4.361l-1.356-1.517"></path>
                      </svg>
                      <span>Regenerate</span>
                    </button>
                  </div>
                  {categoryLoading[category] ? (
                  <Loader />
                  ) : (
                    <div className="recipe-list">
                      {recipesByCategory[category].map((recipe, index) => (
                        <RecipeCard
                          key={recipe.id || index}
                          id={recipe.id}
                          name={recipe.name}
                          img={recipe.img}
                          url={recipe.url}
                          ingredients={recipe.ingredients}
                          instructions={recipe.instructions}
                          approximateCost={recipe.approximateCost}
                          timePrep={recipe.timePrep}
                          calories={recipe.calories}
                          nutritionalInfo={recipe.nutritionalInfo}
                          perfectFor={recipe.perfectFor}
                          containsIngredient={recipe.containsIngredient}
                          foodPreferences={recipe.foodPreferences}
                        />
                        
                      ))}
                    </div>
                  )}
                  {explanations[category] && (
                    <div className="category-explanation">
                      <h3>Why These Recipes?</h3>
                      <p>{explanations[category]}</p>
                    </div>
                  )}
                </div>
              ))}
          </div>
          <div className="next-step">
            <Link to="/meal-planner/grocery-list">
              Get Grocery List
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}
