import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAppState } from '../context/AppStateContext';
import avatarIcon from '../assets/icons/avatar.svg';
import smallLogo from '../assets/icons/small-logo.svg';
import textLogo from '../assets/icons/text-logo.svg';
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from '@chakra-ui/react'
import { ChakraProvider } from "@chakra-ui/react";
import '../styles/Header.css';
import { signOut } from 'firebase/auth'
import { auth } from '../firebase'
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const { state, setInitialState } = useAppState();
  const { currentUser, userData } = state;
  const location = useLocation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  const handleSignOut = () => {
    auth.signOut();
    setInitialState();
    navigate('/');
    window.location.reload();
  };

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateWindowWidth);
    return () => {
      window.removeEventListener('resize', updateWindowWidth);
    };
  }, []);

  return (
    <ChakraProvider>
      <header>
        <div className="container">
          <div className="left">
            {location.pathname === '/demo' ? (
              <span className="demo-logo">The Weekly Plate</span>
            ) : (
              <Link to="/">
                <img
                  src="https://somethingnutritiousblog.com/wp-content/uploads/2024/08/logo-1.svg"
                  className="custom-logo"
                  alt="Something Nutritious"
                  width="200px"
                />
              </Link>
            )}
          </div>
          {location.pathname === '/demo' ? (
            <button className="primary" onClick={() => window.open('https://calendly.com/thedonovansconsultinggroupllc/1-hour-consulting-call', '_blank')}>
              Book a Demo
            </button> // Show "Book a Demo" button
          ) : currentUser && userData?.firstName ? (
            <Menu className="menu">
              <MenuButton as="div" className="current-user" aria-label="Options" variant="outline">
                <div className="current-user">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="36"
                    height="36"
                    viewBox="0 0 49.564 49.564"
                  >
                    <defs>
                      <clipPath id="clip-path">
                        <rect
                          id="Rectangle_2"
                          width="49.564"
                          height="49.564"
                          transform="translate(0 0)"
                          fill="none"
                        />
                      </clipPath>
                    </defs>
                    <g id="Group_2" transform="translate(0 0)">
                      <g id="Group_1" clipPath="url(#clip-path)">
                        <path
                          id="Path_4"
                          d="M24.782,49.564h0A24.782,24.782,0,1,1,49.564,24.782,24.782,24.782,0,0,1,24.782,49.564"
                          fill="#2b3231"
                        />
                        <path
                          id="Path_5"
                          d="M32.441,34.12H17.12A1.162,1.162,0,0,1,16,32.558a9.147,9.147,0,0,1,8.783-5.442,9.146,9.146,0,0,1,8.783,5.442,1.161,1.161,0,0,1-1.122,1.562M20.013,20.111a4.767,4.767,0,1,1,4.767,4.67,4.724,4.724,0,0,1-4.767-4.67M36.4,33.695a11.043,11.043,0,0,0-5.67-7.47,1.142,1.142,0,0,1-.37-1.741,6.89,6.89,0,0,0,1.484-5.458A7.163,7.163,0,0,0,17.63,20.111,6.874,6.874,0,0,0,19.2,24.49a1.139,1.139,0,0,1-.375,1.735,11.043,11.043,0,0,0-5.67,7.47,2.335,2.335,0,0,0,2.346,2.76H34.056a2.334,2.334,0,0,0,2.346-2.76"
                          fill="#f9fafc"
                          fillRule="evenodd"
                        />
                      </g>
                    </g>
                  </svg>
                  <div>
                    <span className="firstname">{userData.firstName}</span>
                    <span className="membership-status">{userData.membershipStatus?.charAt(0).toUpperCase() + userData.membershipStatus?.slice(1)} Member</span>
                  </div>
                  <HamburgerIcon />
                </div>
              </MenuButton>
              <MenuList>
                <MenuItem>
                  <Link to="/my-meal-plans">My meal plans</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/my-account">Account</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/subscription">Manage Subscription</Link>
                </MenuItem>
                <MenuItem>
                  <Link to="/support">Support</Link>
                </MenuItem>
                <MenuItem className="log-out">
                  <Link to="/" onClick={handleSignOut}>
                    Log out
                  </Link>
                </MenuItem>
              </MenuList>
            </Menu>
          ) : (
            <div className="auth-btns">
              {!location.pathname.includes('/account/login') &&
                !location.pathname.includes('/account/register') && (
                  <>
                    <Link to="/account/login" className="login">Log in</Link>
                    <Link to="/account/register" className="sign-up">Sign up</Link>
                  </>
                )}
            </div>
          )}
        </div>
      </header>
    </ChakraProvider>
  );
};

export default Header;
