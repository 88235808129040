import React, { useState, useEffect } from 'react';
import '../../styles/ManageSubscription.css';
import axios from 'axios';
import SubscribePopup from '../../components/SubscribePopup';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useAppState } from '../../context/AppStateContext';

const stripePromise = loadStripe("pk_test_51P2ZcIFWOACTk8qOENWRgdCqoPTnNBceif1HOAlK6cHx5SDq5HBP4HQV6TAngiLzTgVtI4aXEsAJU7zUk4i6yK6O00A7GutkOW");

const ProPlan = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useAppState();
  const { userData, token } = state;
  const [currentPlan, setCurrentPlan] = useState(userData.membershipStatus ? 'pro' : 'free');
  const [isStripePopupOpen, setIsStripePopupOpen] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [paymentType, setPaymentType] = useState('subscription');
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState('');

  console.log(userData)
  useEffect(() => {
    setCurrentPlan(userData.membershipStatus ? 'pro' : 'free');
  }, [userData]);

  const handleCancelSubscription = async () => {
    setIsLoading(true);
    try {
      if (!userData.subscriptionId) return; 

      const response = await axios.post('https://us-central1-the-weekly-meals.cloudfunctions.net/api/cancel-subscription', {
        subscriptionId: userData.subscriptionId,
        reason: cancelReason // Send the cancellation reason to the backend
      },{
        headers: {
            Authorization: 'Bearer ' + token,
       }});
      if (response.data.success) {
        alert('Subscription canceled successfully.');
        setCurrentPlan('free');
        setIsCancelModalOpen(false); // Close the cancel modal
        window.location.reload();
      } else {
        alert('Failed to cancel the subscription.');
      }
    } catch (error) {
      console.error('Error canceling subscription:', error);
      alert('An error occurred while canceling the subscription.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubscribe = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post('https://us-central1-the-weekly-meals.cloudfunctions.net/api/create-payment-intent/subscription-price');
      setClientSecret(response.data.clientSecret);
      setIsStripePopupOpen(true);
    } catch (error) {
      console.error('Error creating payment intent:', error.response ? error.response.data : error.message);
      alert('An error occurred while initiating the subscription.');
    } finally {
      setIsLoading(false);
      window.location.reload();
    }
  };

  const handleClosePaymentModal = () => {
    setIsStripePopupOpen(false);
  };

  const handleCancelClick = () => {
    setIsCancelModalOpen(true);
  };

  const handleCancelConfirm = () => {
    handleCancelSubscription();
  };

  const handleCancelClose = () => {
    setIsCancelModalOpen(false);
  };

  const options = {
    clientSecret,
  };

  return (
    <div className="subscription-card">
      <div className="card-header">
        <div className="membership-icon"></div>
        <h2>Membership</h2>
        <div className="current-plan">
          <span className={`dot ${currentPlan.toLowerCase()}`}></span> Current Plan: {currentPlan}
        </div>
      </div>

      <div className="plan-details">
        <h3>Subscription</h3>
        <ul>
          <li>Up to Two Meals a month</li>
          <li>Unlimited Recipes</li>
          <li>Download as much as you want</li>
        </ul>
        <div className="price">
          <span>$2.99</span>/Month
        </div>
      </div>

      <div className="plan-actions">
        {currentPlan === 'pro' ? (
          <button 
            className="cancel-subscription" 
            onClick={handleCancelClick}
            disabled={isLoading}
          >
            {isLoading ? 'Cancelling...' : 'Cancel My Subscription'}
          </button>
        ) : (
          <button 
            className="subscribe" 
            onClick={handleSubscribe}
            disabled={isLoading}
          >
            {isLoading ? 'Subscribing...' : 'Subscribe to Pro'}
          </button>
        )}
      </div>

      {isStripePopupOpen && clientSecret && (
        <div className="payment-modal">
          <Elements stripe={stripePromise} options={options}>
            <SubscribePopup
              isOpen={isStripePopupOpen}
              onClose={handleClosePaymentModal}
              paymentType={paymentType}
              setPaymentType={setPaymentType}
            />
          </Elements>
        </div>
      )}

      {isCancelModalOpen && (
        <div className="cancel-modal">
          <div className="modal-content">
            <h3>Are you sure you want to cancel your subscription?</h3>
            <p>Please let us know why you are canceling:</p>
            <textarea 
              value={cancelReason} 
              onChange={(e) => setCancelReason(e.target.value)} 
              placeholder="Reason for cancellation (optional)"
            />
            <div className="modal-actions">
              <button onClick={handleCancelConfirm} disabled={isLoading}>
                {isLoading ? 'Cancelling...' : 'Yes, Cancel'}
              </button>
              <button onClick={handleCancelClose}>No, Keep Subscription</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProPlan;
