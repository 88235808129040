import { sendPasswordResetEmail } from 'firebase/auth'
import {useState} from 'react'
import {auth, analytics} from '../firebase'

export default function ForgotPassword() {
    const [email, setEmail] = useState('')
    const [error, setError] = useState('')

    const handleForgotPassword = () => {
        sendPasswordResetEmail(auth, email)
          .then(() => {
            // Password reset email sent successfully
            alert('Password reset email has been sent. Please check your email inbox.');
          })
          .catch(error => {
            setError(error.message);
          });
      };

    return (
        <div className="auth">
            <h2>Retrieve Password</h2>
            <form onSubmit={handleForgotPassword} name='forgot-password_form'>
                <input
                    type='email'
                    value={email}
                    required
                    placeholder='Enter your email'
                    onChange={e => setEmail(e.target.value)}
                />
          <button type='submit'>Send</button>
        </form>
        </div>
    )
}
