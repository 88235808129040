import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../styles/StepsProgressBar.css';

export default function StepsProgressBar() {
  const [activeStep, setActiveStep] = useState(1);
  const location = useLocation();

  const routeToStepMap = {
    '/': 1,
    '/meal-planner/recipes': 2,
    '/meal-planner/sign-up': 3,
    '/meal-planner/log-in': 3,
    '/meal-planner/grocery-list': 3,
  };

  const getColor = (stepNumber, colorType) => {
    if (colorType === 'background') {
      return activeStep === stepNumber ? '#c4c4c4' : '#ffffff';
    }
    if (colorType === 'inside') {
      return activeStep === stepNumber ? '#f9fafc' : '#c4c4c4';
    }
  };

  const steps = [
    { desc: 'Preferences', number: 1, link: '/', svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="92.471" height="92.471" viewBox="0 0 92.471 92.471">
        <g id="Group_62" data-name="Group 62" transform="translate(-425 -261)">
          <path id="Path_6" data-name="Path 6" d="M92.471,46.236A46.236,46.236,0,1,1,46.236,0,46.236,46.236,0,0,1,92.471,46.236" transform="translate(425 261)" fill="#c4c4c4" />
          <g id="Group_3" data-name="Group 3" transform="translate(448.701 286.284)">
            <path id="Path_7" data-name="Path 7" d="M21.349,29.723H24.01a6.936,6.936,0,0,0,12.013,0H59.484a3.466,3.466,0,0,0,0-6.933H36.023a6.936,6.936,0,0,0-12.013,0H21.349a3.466,3.466,0,0,0,0,6.933m6.067-3.466a2.6,2.6,0,1,1,2.6,2.6,2.6,2.6,0,0,1-2.6-2.6" transform="translate(-17.883 -19.323)" fill="#fff" />
            <path id="Path_8" data-name="Path 8" d="M59.484,34.308H56.824a6.936,6.936,0,0,0-12.013,0H21.349a3.466,3.466,0,1,0,0,6.933H44.811a6.936,6.936,0,0,0,12.013,0h2.661a3.466,3.466,0,0,0,0-6.933m-6.067,3.466a2.6,2.6,0,1,1-2.6-2.6,2.6,2.6,0,0,1,2.6,2.6" transform="translate(-17.883 -16.973)" fill="#fff" />
            <path id="Path_9" data-name="Path 9" d="M59.484,45.825H36.023a6.936,6.936,0,0,0-12.013,0H21.349a3.466,3.466,0,1,0,0,6.933H24.01a6.936,6.936,0,0,0,12.013,0H59.484a3.466,3.466,0,0,0,0-6.933M32.617,49.291a2.6,2.6,0,1,1-2.6-2.6,2.6,2.6,0,0,1,2.6,2.6" transform="translate(-17.883 -14.624)" fill="#fff" />
          </g>
        </g>
      </svg>
    ) },
    { desc: 'Meals', number: 2, link: '/meal-planner/recipes', svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="90" height="91" viewBox="0 0 90 91">
        <defs>
          <clipPath id="clipPath">
            <rect id="Rectangle_5" data-name="Rectangle 5" width="90" height="91" transform="translate(0.066 -0.204)" fill="none"/>
          </clipPath>
        </defs>
        <g id="Group_5" data-name="Group 5" transform="translate(-0.066 0.204)">
          <g id="Group_4" data-name="Group 4" transform="translate(0 0)" clipPath="url(#clipPath)">
            <circle id="Ellipse_1" data-name="Ellipse 1" cx="43.373" cy="43.373" r="43.373" transform="translate(1.777 1.778)" fill={activeStep > 1 ? "#c4c4c4" : "none"} stroke="#c4c4c4" strokeMiterlimit="10" strokeWidth="3"/>
            <path id="Path_10" data-name="Path 10" d="M29.669,57.463l7.681-9.314,3.514,4.482-6.772,8.611c-2.593,3.3-7.391-.177-4.423-3.779m8.97-21.21c.732-2.387.4-4.426-2.354-7.455l-6.716-8.08c-.952-1.119-3.2.525-2.26,1.881l5.371,6.616a1.249,1.249,0,0,1-1.921,1.6L25.2,23.97c-1.035-1.186-3.174.38-2.091,1.736,1.564,1.884,3.988,4.961,5.555,6.845a1.077,1.077,0,1,1-1.637,1.364l-5.514-6.8a1.5,1.5,0,0,0-2.483.694c-.378,1.1.549,1.9,1.182,2.711l6.154,7.951C28.281,40.687,30.417,42,32.9,41.256a6.088,6.088,0,0,0,1.345-.654L50.779,61.948a2.625,2.625,0,0,0,3.724.344l.257-.211a2.962,2.962,0,0,0,.383-4.189L37.63,37.771a4.723,4.723,0,0,0,1.009-1.518m4.682,4.655,2.722-3.3c-3.5-8.682,9.476-20.253,15.768-14.933,7.644,6.469-3.716,22.061-11.7,18.207l-3.226,4.1Z" transform="translate(3.507 3.766)" fill={activeStep === 1 ? "#c4c4c4" : "#fff"} />
          </g>
        </g>
      </svg>
    ) },
    { desc: 'Grocery List', number: 3, link: '/meal-planner/grocery-list', svg: (
      <svg xmlns="http://www.w3.org/2000/svg" width="91.744" height="91.744" viewBox="0 0 91.744 91.744">
        <defs>
          <clipPath id="clipPath">
            <rect id="Rectangle_6" data-name="Rectangle 6" width="91.744" height="91.744" fill="none"/>
          </clipPath>
        </defs>
        <g id="Group_6" data-name="Group 6" transform="translate(0 0)" clipPath="url(#clipPath)">
          <circle id="Ellipse_2" data-name="Ellipse 2" cx="44.066" cy="44.066" r="44.066" transform="translate(1.806 1.806)" fill={activeStep > 2 ? "#c4c4c4" : "none"} stroke={activeStep < 3 ? "#c4c4c4" : "none"} strokeMiterlimit="10" strokeWidth="3"/>
          <path id="Path_11" data-name="Path 11" d="M30.453,21.666c0-.5-.016-.961,0-1.423.061-1.533.663-2.142,2.194-2.23a7.58,7.58,0,0,1,.936,0,4.012,4.012,0,0,0,4.133-1.788c2.291-3.208,7.129-2.71,9.212.721A1.786,1.786,0,0,0,48.9,18.015a4.664,4.664,0,0,1,.622-.006C53.314,18,53.314,18,53.705,21.858c2.241,0,4.51,0,6.781,0,3.287.007,4.661,1.357,4.661,4.621.006,14.927.006,22.721,0,37.649,0,3.138-1.442,4.584-4.567,4.585q-18.568.009-37.136,0c-3.126,0-4.55-1.444-4.551-4.6-.007-14.978-.008-22.825,0-37.8,0-3.04,1.439-4.436,4.519-4.448q3.276-.014,6.552-.029a2.449,2.449,0,0,0,.491-.167M61.21,64.728V25.792H53.7c-.417,3.782-.417,3.782-4.165,3.782H34.084c-3.315,0-3.458-.136-3.678-3.475-.006-.089-.09-.175-.165-.309h-7.4V64.728ZM49.678,21.852c-1.2,0-2.285-.025-3.364.006a2.024,2.024,0,0,1-2.368-1.911c-.14-1.146-.7-1.867-1.86-1.9-1.243-.031-1.848.694-2,1.916a1.981,1.981,0,0,1-2.232,1.888c-1.129-.011-2.258,0-3.476,0v3.728h15.3Z" transform="translate(3.853 2.871)" fill={getColor(3, 'inside')} />
          <path id="Path_12" data-name="Path 12" d="M32.07,49c1.889-1.925,3.474-3.555,5.077-5.166.944-.948,1.928-1.084,2.639-.411.773.732.656,1.689-.374,2.726q-2.866,2.888-5.758,5.749c-1.1,1.088-1.816,1.094-2.907.041-.943-.911-1.863-1.848-2.784-2.784a1.584,1.584,0,0,1-.149-2.435,1.531,1.531,0,0,1,2.418.134c.6.625,1.134,1.318,1.837,2.146" transform="translate(5.572 8.773)" fill={getColor(3, 'inside')} />
          <path id="Path_13" data-name="Path 13" d="M32.088,38.2c1.843-1.879,3.428-3.512,5.033-5.124,1.063-1.067,1.952-1.168,2.714-.362.712.753.588,1.631-.4,2.625q-2.87,2.888-5.759,5.756c-1.132,1.122-1.858,1.115-3.01-.008-.846-.825-1.681-1.663-2.509-2.506-.96-.978-1.085-1.9-.379-2.633.724-.755,1.711-.629,2.717.4.517.53.968,1.122,1.592,1.853" transform="translate(5.576 6.566)" fill={getColor(3, 'inside')} />
          <path id="Path_14" data-name="Path 14" d="M45.668,38.125c-1.049,0-2.1.007-3.147,0a1.536,1.536,0,0,1-1.706-1.594,1.5,1.5,0,0,1,1.67-1.616q3.148-.022,6.295,0a1.606,1.606,0,1,1,.036,3.209c-1.049.026-2.1.006-3.147,0" transform="translate(8.327 7.121)" fill={getColor(3, 'inside')} />
          <path id="Path_15" data-name="Path 15" d="M45.7,45.674c1.049,0,2.1-.029,3.146.008a1.5,1.5,0,0,1,1.625,1.656,1.463,1.463,0,0,1-1.592,1.548c-2.141.033-4.283.024-6.423,0a1.493,1.493,0,0,1-1.64-1.642,1.55,1.55,0,0,1,1.737-1.566c1.049-.01,2.1,0,3.146,0" transform="translate(8.327 9.317)" fill={getColor(3, 'inside')} />
        </g>
      </svg>
    ) },
  ];

  useEffect(() => {
    const currentStep = routeToStepMap[location.pathname] || 1;
    setActiveStep(currentStep);
  }, [location]);

  // Hide the step progress bar if the current route is '/meal-planner/success'
  if (location.pathname === '/meal-planner/success') {
    return null;
  }

  return (
    <div className="progress-bar">
      <div id="steps">
        {steps.map((step, index) => (
          <Link
            to={step.link}
            key={index}
            className={`step ${activeStep === step.number ? 'active' : ''}`}
            data-desc={step.desc}
            onClick={() => setActiveStep(step.number)}
            tabIndex="0"
          >
            {step.svg}
          </Link>
        ))}
      </div>
    </div>
  );
}
