import React, { useState } from 'react';
import '../styles/Account.css'
import { useNavigate, Link } from 'react-router-dom'
import { auth } from '../firebase';
import axios from 'axios';
import { signInWithEmailAndPassword } from 'firebase/auth';
import Footer from '../components/Footer.jsx'

const AccountCreation = ({ redirectLink }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state
  const navigate = useNavigate();

  const validatePassword = () => {
    let isValid = true;

    // Check if password meets minimum length requirement
    if (password.length < 6) {
      setError('Password should be at least 6 characters long');
      return false;
    }

    // Check if password contains at least one lowercase letter
    if (!/[a-z]/.test(password)) {
      setError('Password should contain at least one lowercase letter');
      return false;
    }

    // Check if password contains at least one uppercase letter
    if (!/[A-Z]/.test(password)) {
      setError('Password should contain at least one uppercase letter');
      return false;
    }

    // Check if password contains at least one digit
    if (!/\d/.test(password)) {
      setError('Password should contain at least one digit');
      return false;
    }

    // Check if password matches the confirmed password
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return false;
    }

    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      if (!validatePassword()) {
        setLoading(false);
        return;
      }

      const response = await axios.post(
        'https://us-central1-the-weekly-meals.cloudfunctions.net/api/register',
        {
          email: email,
          password: password,
          firstName: firstName,
        }
      );

      // Automatically log in the user after successful registration
      signInWithEmailAndPassword(auth, email, password)
        .then(() => {
          if (location.pathname.includes('/meal-planner/sign-up')) {
            navigate('/meal-planner/grocery-list');
          } else {
            navigate('/');
          }
        })
        .catch((error) => {
          setError('An error occurred while logging in');
          setLoading(false);
        });
    } catch (error) {
      console.log(error)
      setLoading(false);

      if (error.response && error.response.status === 500) {
        setError('Email address is already in use.');
      } else if (error.response && error.response.status === 400) {
        if (error.response.data === 'Email already in use') { // Check for specific error message
          setError('Email address is already in use.');
        } else {
          setError(error.response.data); // Other 400 errors
        }
      } else {
        setError('Network error. Please try again later.');
      }

    }
  };

  return (
    <>
      <div className='center'>
        <div className='auth'>
          <h1>Register</h1>
          {error && <div className='auth__error'>{error}</div>}
          <form onSubmit={handleSubmit} name='registration_form'>
            <input
              type='email'
              value={email}
              placeholder="Enter your email"
              required
              onChange={e => setEmail(e.target.value)} />

            <input
              type='text'
              value={firstName}
              required
              placeholder='Enter your first name'
              onChange={e => setFirstName(e.target.value)} />

            <input
              type='password'
              value={password}
              required
              placeholder='Enter your password'
              onChange={e => setPassword(e.target.value)} />

            <input
              type='password'
              value={confirmPassword}
              required
              placeholder='Confirm password'
              onChange={e => setConfirmPassword(e.target.value)} />

            <button type='submit' disabled={loading}> {loading ? 'Loading...' : 'Register'} </button>
          </form>
          {location.pathname.includes('/meal-planner/sign-up') ? (
            <span>
              Already have an account? <Link to='/meal-planner/log-in'>Log in</Link>
            </span>
          ) : (
            <span>
              Already have an account? <Link to='/account/login'>Log in</Link>
            </span>
          )}
        </div>
      </div>
      {/* <Footer /> */}
    </>
  );
};

export default AccountCreation;
