import '../../styles/FoodPreferences.css';
import { Link } from 'react-router-dom';
import { useAppState } from '../../context/AppStateContext';
import StripePopup from '../StripePopup'
import { loadStripe } from '@stripe/stripe-js';
import '../../styles/StripePopup.css';
import { Elements } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState, useRef } from 'react';

const stripePromise = loadStripe("pk_test_51P2ZcIFWOACTk8qOENWRgdCqoPTnNBceif1HOAlK6cHx5SDq5HBP4HQV6TAngiLzTgVtI4aXEsAJU7zUk4i6yK6O00A7GutkOW");

const DietForm = ({ regenerateCategory, getRecipesWithPreferences }) => {
  const { state, setPreferences } = useAppState();

  const defaultPreferences = {
    "servings": 2,
    "budget": 100,
    "diet": "no",
    "includeIngredients": [],
    "avoidIngredients": [],
    "meals": {
        "breakfast": 2,
        "main": 3,
        "snacks": 1,
        "salads": 1,
        "sweets": 1
    }
};

  const [includeIngredient, setIncludeIngredient] = useState('');
  const [avoidIngredient, setAvoidIngredient] = useState('');
  const [activeSection, setActiveSection] = useState(0);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(true);
  const submitButtonRef = useRef(null);
  const [clientSecret, setClientSecret] = useState('');
  const [userPreferences, setUserPreferences] = useState(defaultPreferences)
  const [paymentType, setPaymentType] = useState('subscription'); // Define paymentType state here
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!state.preferences) {
      setPreferences(defaultPreferences);
      getRecipesWithPreferences(defaultPreferences); 
    } else {
      getRecipesWithPreferences(state.preferences); 
    }
  }, []);

  useEffect(() => {
    if (state.preferences) {
      setUserPreferences(state.preferences);
    } else {
      setUserPreferences(defaultPreferences);
    }
  }, [state]);

  const appearance = {
    theme: 'stripe',
  };

  const options = {
    clientSecret,
    appearance,
  };

  const handleGetMealPlan = async (e) => {
    e.preventDefault();
    const endpoint = paymentType === "subscription" ? "subscription-price" : "one-time-price";

    try {
      const response = await fetch(`https://us-central1-the-weekly-meals.cloudfunctions.net/api/create-payment-intent/${endpoint}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ items: [{ id: 'meal-plan' }], paymentType }),
      });

      const data = await response.json();
      setClientSecret(data.clientSecret);
      setIsPaymentModalOpen(true);
    } catch (error) {
      console.error('Error creating payment intent:', error);
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry && entry.isIntersecting) {
          if (submitButtonRef.current) {
            submitButtonRef.current.classList.add('visible');
          }
        } else {
          if (submitButtonRef.current) {
            submitButtonRef.current.classList.remove('visible');
          }
        }
      },
      {
        threshold: 0.5,
      }
    );

    if (submitButtonRef.current) {
      observer.observe(submitButtonRef.current);
    } else {
      console.error('submitButtonRef is not a valid DOM element');
    }

    return () => {
      if (submitButtonRef.current instanceof Element) {
        observer.unobserve(submitButtonRef.current);
      }
    };
  }, []);

  const handleClosePaymentModal = () => {
    setIsPaymentModalOpen(false);
  };

  const handleMealPreferencesChange = (meal, value) => {
    const recipesCount = Math.min(value, 4);
    const updatedPreferences = {
      ...state.preferences,
      meals: {
        ...state.preferences.meals,
        [meal]: recipesCount,
      },
    };
  
    setPreferences(updatedPreferences);
    getRecipesWithPreferences(updatedPreferences);
  };

  const handleInputChange = (field, value) => {
    setPreferences({
      ...state.preferences,
      [field]: value,
    });
  };

  const handleAddIncludeIngredient = () => {
    if (includeIngredient.trim() !== '') {
      const updatedPreferences = {
        ...state.preferences,
        includeIngredients: [...state.preferences.includeIngredients, includeIngredient],
      };
  
      setPreferences(updatedPreferences);
      setIncludeIngredient('');
      getRecipesWithPreferences(updatedPreferences);
    }
  };

  const handleAddAvoidIngredient = () => {
    if (avoidIngredient.trim() !== '') {
      const updatedPreferences = {
        ...state.preferences,
        avoidIngredients: [...state.preferences.avoidIngredients, avoidIngredient],
      };
  
      setPreferences(updatedPreferences);
      setAvoidIngredient('');
      getRecipesWithPreferences(updatedPreferences);
    }
  };


  const handleRemoveIncludeIngredient = (ingredient) => {
    const updatedPreferences = {
      ...state.preferences,
      includeIngredients: state.preferences.includeIngredients.filter(item => item !== ingredient),
    };
  
    setPreferences(updatedPreferences);
    getRecipesWithPreferences(updatedPreferences);
  };

  const handleRemoveAvoidIngredient = (ingredient) => {
    const updatedPreferences = {
      ...state.preferences,
      avoidIngredients: state.preferences.avoidIngredients.filter(item => item !== ingredient),
    };
  
    setPreferences(updatedPreferences);
    getRecipesWithPreferences(updatedPreferences);
  };

  const handleIncludeIngredientKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddIncludeIngredient();
    }
  };

  const handleAvoidIngredientKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleAddAvoidIngredient();
    }
  };

  const handleNextSection = (e) => {
    e.preventDefault();
    const sections = document.querySelectorAll('.food-preferences > form section');
    const nextIndex = activeSection + 1;

    if (nextIndex < sections.length) {
      const section = sections[nextIndex];
      const yOffset = window.innerHeight * 1 / 3;
      const y = section.getBoundingClientRect().top + window.pageYOffset - yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });
      setActiveSection(nextIndex);
    }
  };

  const handleScroll = () => {
    const sections = document.querySelectorAll('.food-preferences > form section');
    let currentSection = activeSection;

    sections.forEach((section, index) => {
      const rect = section.getBoundingClientRect();
      const threshold = window.innerHeight * 2 / 3;

      if (rect.top <= threshold && rect.bottom >= threshold) {
        currentSection = index;
      }
    });

    setActiveSection(currentSection);
  };

  useEffect(() => {
    const throttle = (fn, wait) => {
      let time = Date.now();
      return function () {
        if ((time + wait - Date.now()) < 0) {
          fn();
          time = Date.now();
        }
      };
    };

    const throttledHandleScroll = throttle(handleScroll, 100);

    window.addEventListener('scroll', throttledHandleScroll);
    return () => {
      window.removeEventListener('scroll', throttledHandleScroll);
    };
  }, []);

  return (
    <>
      <div className="food-preferences">
        <form>
        <section className={`question num-meals ${activeSection === 0 ? 'active' : ''}`}>
          <h3>How many different recipes would you like to have this week?</h3>
          <div className="meal-preferences">
          {userPreferences && userPreferences.meals && Object.keys(userPreferences.meals).map((meal) => (
              <div className="meal-item" key={meal}>
                <label>{`${meal.charAt(0).toUpperCase() + meal.slice(1)} recipes`}</label>
                <div className="counter">
                  <button type="button" onClick={() => handleMealPreferencesChange(meal, Math.max(0, userPreferences.meals[meal] - 1))}>-</button>
                  <span>{userPreferences.meals[meal]}</span>
                  <button type="button" onClick={() => handleMealPreferencesChange(meal, Math.min(4, userPreferences.meals[meal] + 1))}>+</button>
                </div>
              </div>
            ))
          }
          </div>
        </section>
          <section className={`question diet ${activeSection === 1 ? 'active' : ''}`}>
            <h3>Do you follow any diet?</h3>
            <div className="diet-options-container">
              {['vege', 'vegan', 'quick-easy', 'mostly-meat', 'no-seafood', 'no'].map(diet => (
                <button
                  key={diet}
                  type="button"
                  onClick={() => handleInputChange('diet', diet)}
                  className={userPreferences.diet === diet ? 'active' : ''}
                >
                  {diet === 'no' ? 'No preferences' : diet.charAt(0).toUpperCase() + diet.slice(1)}
                </button>
              ))}
            </div>
          </section>
          <section className={`question ingredients ${activeSection === 2 ? 'active' : ''}`}>
            <div className="ingredients-to-include">
              <h3>
                Are there any ingredients you wish to <strong>include</strong>?
              </h3>
              <div>
                <div className="ingredients-container">
                  {userPreferences.includeIngredients.map((ingredient, index) => (
                    <div key={index} className="ingredient">
                      <button type="button">
                        <span>{ingredient}</span>
                        <svg onClick={() => handleRemoveIncludeIngredient(ingredient)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M6.7072 5.29289C6.31668 4.90237 5.68351 4.90237 5.29299 5.29289C4.90246 5.68342 4.90246 6.31658 5.29299 6.70711L10.5858 12L5.29289 17.2929C4.90237 17.6834 4.90237 18.3166 5.29289 18.7071C5.68342 19.0976 6.31658 19.0976 6.70711 18.7071L12 13.4142L17.293 18.7071C17.6835 19.0976 18.3167 19.0976 18.7072 18.7071C19.0977 18.3166 19.0977 17.6834 18.7072 17.2929L13.4143 12L18.7071 6.70711C19.0976 6.31658 19.0976 5.68342 18.7071 5.29289C18.3166 4.90237 17.6834 4.90237 17.2929 5.29289L12 10.5857L6.7072 5.29289Z" fill="#FCFCFC" />
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
                <div className="input-with-button">
                  <input
                    type="text"
                    value={includeIngredient}
                    onChange={(event) => setIncludeIngredient(event.target.value)}
                    onKeyDown={handleIncludeIngredientKeyDown}
                    placeholder="Add more"
                  />
                  <button type="button" onClick={handleAddIncludeIngredient} className="add-button">
                    Add
                  </button>
                </div>
              </div>
            </div>
          </section>


          <section className={`question ingredients ${activeSection === 3 ? 'active' : ''}`}>
            <div className="ingredients-to-avoid">
              <h3>
                Are there any ingredients you wish to <strong>avoid</strong>?
              </h3>
              <div>
                <div className="ingredients-container">
                  {userPreferences.avoidIngredients.map((ingredient, index) => (
                    <div key={index} className="ingredient">
                      <button type="button">
                        <span>{ingredient}</span>
                        <svg onClick={() => handleRemoveAvoidIngredient(ingredient)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M6.7072 5.29289C6.31668 4.90237 5.68351 4.90237 5.29299 5.29289C4.90246 5.68342 4.90246 6.31658 5.29299 6.70711L10.5858 12L5.29289 17.2929C4.90237 17.6834 4.90237 18.3166 5.29289 18.7071C5.68342 19.0976 6.31658 19.0976 6.70711 18.7071L12 13.4142L17.293 18.7071C17.6835 19.0976 18.3167 19.0976 18.7072 18.7071C19.0977 18.3166 19.0977 17.6834 18.7072 17.2929L13.4143 12L18.7071 6.70711C19.0976 6.31658 19.0976 5.68342 18.7071 5.29289C18.3166 4.90237 17.6834 4.90237 17.2929 5.29289L12 10.5857L6.7072 5.29289Z" fill="#FCFCFC" />
                        </svg>
                      </button>
                    </div>
                  ))}
                </div>
                <div className="input-with-button">
                  <input
                    type="text"
                    value={avoidIngredient}
                    onChange={(event) => setAvoidIngredient(event.target.value)}
                    onKeyDown={handleAvoidIngredientKeyDown}
                    placeholder="Add more"
                  />
                  <button type="button" onClick={handleAddAvoidIngredient} className="add-button">
                    Add
                  </button>
                </div>
              </div>
            </div>
          </section>

          <div className="submit" ref={submitButtonRef}>
            <Link onClick={handleGetMealPlan}>Next</Link>
          </div>
        </form>
      </div>

      {isPaymentModalOpen && clientSecret && (
        <div className="payment-modal">
          <Elements stripe={stripePromise} options={options}>
            <StripePopup
              isOpen={isPaymentModalOpen}
              onClose={handleClosePaymentModal}
              paymentType={paymentType}
              setPaymentType={setPaymentType}
            />
          </Elements>
        </div>
      )}
    </>
  );
};

export default DietForm;
