import React, { useState } from 'react';
import { useAppState } from '../../context/AppStateContext';
import '../../styles/MyAccount.css';
import { firebaseAnalytics } from '../../firebase';
import { logEvent } from "firebase/analytics";

export default function MyAccount() {
    const { state } = useAppState();
    const { currentUser, token, userData } = state;
    const [showConfirmation, setShowConfirmation] = useState(false);

    function displayDeleteAccountModal() {
        document.querySelector('body').classList.add('open-popup');
        setShowConfirmation(true);
    }

    function hideDeleteAccountModal() {
        document.querySelector('body').classList.remove('open-popup');
        setShowConfirmation(false);
    }

    function confirmDeleteUser() {
        // Make a request to your backend API to delete the user
        fetch('https://us-central1-the-weekly-meals.cloudfunctions.net/api/deleteUser', {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(response => {
                if (response.ok) {
                    console.log('User deletion request successful');
                    alert('Your account has been successfully deleted.');
                    window.location.reload();
                } else {
                    console.error('Failed to delete user:', response.statusText);
                    alert('Failed to delete your account. Please try again later.');
                    setShowConfirmation(false);
                }
            })
            .catch(error => {
                console.error('Error deleting user:', error);
                alert('An error occurred while deleting your account. Please try again later.');
                setShowConfirmation(false);
            });
    }

    return (
        <div className="my-account">
            <div className="account-info-card">
                <div className="account-icon">
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="94"
                    height="48"
                    viewBox="0 0 94 48"
                    >
                    <defs>
                        <clipPath id="clipPath">
                        <rect
                            width="94"
                            height="48"
                            fill="#616161"
                            data-name="Rectangle 332"
                            rx="24"
                            transform="translate(298.092 181.821)"
                        ></rect>
                        </clipPath>
                    </defs>
                    <g
                        clipPath="url(#clipPath)"
                        data-name="Mask Group 2"
                        transform="translate(-298.092 -181.821)"
                    >
                        <g transform="translate(312.287 182.099)">
                        <g fill="#616161" data-name="Group 54">
                            <path
                            d="M81.8 102.1H30.791a7.1 7.1 0 00-7.1 7.1v32.692a7.1 7.1 0 007.1 7.1H81.8a7.1 7.1 0 007.1-7.1V109.2a7.1 7.1 0 00-7.1-7.1zm2.985 39.821A2.99 2.99 0 0181.8 144.9H30.791a2.99 2.99 0 01-2.985-2.985V109.2a2.972 2.972 0 012.985-2.985H81.8a2.99 2.99 0 012.985 2.985v32.72z"
                            data-name="Path 63"
                            transform="translate(-23.689 -102.099)"
                            ></path>
                            <path
                            d="M311.923 202.895a2.073 2.073 0 110-4.145h20.919a2.073 2.073 0 110 4.145z"
                            data-name="Path 64"
                            transform="translate(-276.468 -187.475)"
                            ></path>
                            <path
                            d="M311.923 284.384a2.073 2.073 0 110-4.145h20.919a2.073 2.073 0 110 4.145z"
                            data-name="Path 65"
                            transform="translate(-276.468 -259.458)"
                            ></path>
                            <path
                            d="M341.771 366.111a2.073 2.073 0 010-4.145h17.437a2.073 2.073 0 110 4.145z"
                            data-name="Path 66"
                            transform="translate(-302.834 -331.651)"
                            ></path>
                            <path
                            d="M111.1 199.221a15.341 15.341 0 00-7.9-9.23 9.893 9.893 0 10-13.1 0 15.253 15.253 0 00-7.9 9.23 2.084 2.084 0 00.138 1.575 2.03 2.03 0 001.216 1.022 2.3 2.3 0 00.608.083 2.039 2.039 0 001.962-1.465 10.99 10.99 0 0121.03 0 2.1 2.1 0 001.962 1.465 2.3 2.3 0 00.608-.083 2.03 2.03 0 001.216-1.022 2.092 2.092 0 00.16-1.575zm-14.481-10.667a5.969 5.969 0 115.969-5.969 5.979 5.979 0 01-5.969 5.969z"
                            data-name="Path 67"
                            transform="translate(-75.285 -164.457)"
                            ></path>
                        </g>
                        </g>
                    </g>
                </svg>
                </div>
                <h2>Account Info</h2>
                <p className="subtext"></p>
                {
                    userData && currentUser &&
                    <div className="account-details">
                        <div className="detail-row">
                            <span className="label">Name</span>
                            <span className="value">{userData.firstName}</span>
                        </div>
                        <div className="detail-row">
                            <span className="label">My Email</span>
                            <span className="value">{userData.email}</span>
                        </div>
                        <div className="detail-row">
                            <span className="label">Account Id</span>
                            <span className="value">{currentUser.uid}</span>
                        </div>
                    </div>
                }
                <button onClick={displayDeleteAccountModal} className="delete-my-account">Delete My Account</button>
            </div>

            {showConfirmation && (
                <div className="confirmation-dialog">
                    <button className="close-button" onClick={hideDeleteAccountModal}>
                        <svg color="currentColor" width="16" height="16" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg" fill="none" class="BaseIconstyles__BaseIconRoot-sc-1uucpm5-0 fSAOeg"><path fill-rule="evenodd" clip-rule="evenodd" d="M22.2392 3.74389L4.23919 21.7439L3.03711 20.5418L21.0371 2.54181L22.2392 3.74389Z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M4.23919 2.54181L22.2392 20.5418L21.0371 21.7439L3.03711 3.74389L4.23919 2.54181Z" fill="currentColor"></path></svg>
                    </button>
                    <h4>Delete account</h4>
                    <p>Are you sure you want to delete your account?</p>
                    <button className="delete" onClick={confirmDeleteUser}>Yes, delete</button>
                    <button className="cancel" onClick={hideDeleteAccountModal}>Cancel</button>
                </div>
            )}
        </div>
    );
}
