import '../styles/Footer.css';
import React, { useState } from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';

function Footer() {
    const [email, setEmail] = useState('');

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Handle newsletter submission (e.g., send to API)
        console.log('Submitted:', email);
        // Clear the email input after submission
        setEmail('');
    };

    return (
        <>
        <footer className="footer-container">
            <div>
                <div className="footer-links">
                    <ul>
                        <li><a href="/support">Support</a></li>
                        <li><a href="/terms-and-conditions">Terms and Conditions</a></li>
                        <li><a href="/privacy-policy">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </footer>

        {/* Copyright Section */}
        <div className="copyright">
            <p>&copy; 2024 The Weekly Plate. All rights reserved.</p>
        </div>
        </>
    );
}

export default Footer;
