import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppState } from '../../context/AppStateContext';
import { startOfWeek, endOfWeek, format } from 'date-fns';
import {
  Modal, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalFooter
} from "@nextui-org/react";

function GroceryListPopup({ groceryListToDisplay, onClose }) {
  return (
    <div className="grocery-list-popup">
      <button className="close-button" onClick={onClose}>X</button>
      {Object.keys(groceryListToDisplay).map((aisle, index) => (
        <div key={index}>
          <h3>{aisle}</h3>
          <ul>
            {groceryListToDisplay[aisle].map((item, itemIndex) => (
              <li key={itemIndex}>
                {item.amount} {item.unit && item.unit} {item.name}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

function PreviousWeeks({ userData, weekID }) {
  const { state } = useAppState();
  const { allRecipes } = state;
  const [previousWeeks, setPreviousWeeks] = useState([]);
  const [groceryListToDisplay, setGroceryListToDisplay] = useState({});
  const [showGroceryList, setShowGroceryList] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const recipesPerPage = 4;

  const closeGroceryListPopup = () => {
    setShowGroceryList(false);
    document.querySelector('body').classList.remove('open-popup');
  };

  function getWeekDates(weekId) {
    const [year, weekNumber] = weekId.split('-W');
    const startDate = startOfWeek(new Date(year, 0, 1), { weekStartsOn: 1 });
    startDate.setDate(startDate.getDate() + (weekNumber - 1) * 7);
    const endDate = endOfWeek(startDate, { weekStartsOn: 1 });
    const formattedStartDate = format(startDate, "EEE, MMM. do yyyy");
    const formattedEndDate = format(endDate, "EEE, MMM. do yyyy");
    return `${formattedStartDate} - ${formattedEndDate}`;
  }

  function getWeekGroceryList(previousWeekId) {
    let recipesData = [];
    previousWeeks[previousWeekId]?.forEach(id => {
      const recipe = allRecipes.find(recipe => recipe.id === id);
      if (recipe) {
        recipesData.push(recipe);
      }
    });
    const groceryList = {};
    if (recipesData.length > 0) {
      recipesData?.forEach(recipe => {
        recipe.ingredients?.forEach(ingredient => {
          const { name, amount, unit = '', aisle } = ingredient;
          const numericAmount = parseFloat(amount) || 0;
          if (!groceryList[aisle]) {
            groceryList[aisle] = [];
          }
          const existingIngredientIndex = groceryList[aisle].findIndex(
            existingIngredient => existingIngredient.name === name
          );
          if (existingIngredientIndex !== -1) {
            groceryList[aisle][existingIngredientIndex].amount += numericAmount;
          } else {
            const ingredientWithCrossedOut = { ...ingredient, isCrossedOut: false };
            groceryList[aisle].push(ingredientWithCrossedOut);
          }
        });
      });
    }
    setGroceryListToDisplay(groceryList);
    setShowGroceryList(true);
    document.querySelector('body').classList.add('open-popup');
  }

  useEffect(() => {
    if (userData && userData.recipes) {
      const allWeeks = userData.recipes;
      const filteredWeeks = Object.keys(allWeeks)
        .filter(week => week !== weekID)
        .reduce((result, week) => {
          result[week] = allWeeks[week];
          return result;
        }, {});
      const reversedKeys = Object.keys(filteredWeeks).sort((a, b) => parseInt(b.split('-W')[1]) - parseInt(a.split('-W')[1]));
      const reversedFilteredWeeks = reversedKeys.reduce((result, week) => {
        result[week] = filteredWeeks[week];
        return result;
      }, {});
      setPreviousWeeks(reversedFilteredWeeks);
    }
  }, [userData]);

  const indexOfLastRecipe = currentPage * recipesPerPage;
  const indexOfFirstRecipe = indexOfLastRecipe - recipesPerPage;
  const currentRecipes = Object.keys(previousWeeks).slice(indexOfFirstRecipe, indexOfLastRecipe);

  return (
    <>
    <div className="top">
      <h2>Previous Weeks</h2>
    </div>
    <div className="previous-weeks">
      <div className="previous-week-groceries"></div>
      {Object.keys(previousWeeks).length === 0 ? (
        <div>
          <p>No previous weeks available. Start your first week now!</p>
          <Link to="/" className="secondary">Start This Week</Link>
        </div>
      ) : (currentRecipes.map(weekId => (
        <div key={weekId}>
          <div className="week-info">
            <h3>{getWeekDates(weekId)}</h3>
            <p onClick={() => getWeekGroceryList(weekId)}>Grocery list</p>
          </div>
          <ul>
            {previousWeeks[weekId].map((recipeId, i) => {
              if (allRecipes.length > 0) {
              const recipe = allRecipes.find((recipe) => recipe.id === recipeId);
              return recipe ? <li key={i}>
                <Link to={`/recipe/${recipe.id}`}>
                  <img
                    src={recipe.img}
                    alt={recipe.name}
                    onError={(e) => {
                      e.target.onerror = null; // Prevent infinite callback loop
                      e.target.src = ''; // Fallback image or leave as is for no image
                    }}
                    width="150"
                    height="150"
                  />
                  <span>{recipe.name}</span>
                  {
                    recipe.foodPreferences && recipe.foodPreferences.isVegetarian &&
                    <span className="tag vege">Vege</span>
                  }
                </Link>
              </li> : null;
              }
            })}
          </ul>
        </div>
      ))
      )}
      {showGroceryList && <GroceryListPopup groceryListToDisplay={groceryListToDisplay} onClose={closeGroceryListPopup} />}
      <div className="pagination">
        {currentRecipes.length > 0 && (
          <p>Page {currentPage} of {Math.ceil(Object.keys(previousWeeks).length / recipesPerPage)}</p>
        )}
        {Object.keys(previousWeeks).length > recipesPerPage && (
          <div>
            <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
            <button onClick={() => setCurrentPage(currentPage + 1)} disabled={indexOfLastRecipe >= Object.keys(previousWeeks).length}>Next</button>
          </div>
        )}
      </div>
    </div>
    </>
  );
}

export default PreviousWeeks;
