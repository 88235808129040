import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home.jsx';
import Header from './components/Header.jsx';
import AccountCreation from './pages/AccountCreation.jsx';
import Login from './pages/Login.jsx';
import MealPlan from './pages/MealPlan.jsx';
import  ProPlan from './components/UserDashboard/ProPlan.jsx';
import AllRecipes from './pages/AllRecipes.jsx';
import UserDashboard from './pages/Dashboard'
import './App.css'
import { auth } from './firebase'
import { doc, getDoc } from 'firebase/firestore';
import { AppStateProvider, AuthListener, useAppState } from './context/AppStateContext';
import { useLocation } from "react-router-dom"
import ForgotPassword from './pages/ForgotPassword.jsx';
import Support from './components/Support.jsx';
import TermsAndConditions from './pages/TermsAndConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import NotFound from './pages/NotFound.jsx';

function App() {
  return (
    <AppStateProvider>
      <AuthListener />
      <AppContent />
    </AppStateProvider>
  );
}

function AppContent() {
  const { currentUser, userData, allRecipes, token } = useAppState();
  
  return (
    <div className="App">
      <Header currentUser={currentUser} userData={userData} />
      <main>
        <Routes>
          <Route path="/demo" element={<Home />} />
          <Route path="/account/register" element={<AccountCreation />} />
          <Route path="/account/login" element={<Login />} />
          <Route path="/account/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/meal-plan/*"
            element={<MealPlan />}
          />
          <Route path="/*" element={<UserDashboard />} />
          <Route path="/support" element={<Support />} />
          <Route path="/all-recipes" element={<AllRecipes />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="*" element={<NotFound />} /> {/* Catch-all for undefined routes */}
        </Routes>
      </main>
    </div>
  );
}

export default App;