import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import MealPlan from '../../pages/MealPlan'
import StepsProgressBar from '../StepsProgressBar.jsx';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ThisWeekGroceriesList from './ThisWeekGroceriesList';

function ThisWeekRecipes({ currentWeekRecipes, startOfWeekDate, endOfWeekDate, weekID, userData }) {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      {
        currentWeekRecipes ? '' : <>
          <StepsProgressBar />
        </>
      }
      <div className="current-week-recipes">
      {/* {
        currentWeekRecipes ? '' : <>
          <div className="current-week top">
            <h2>This week</h2>
            <p><span>{startOfWeekDate} - {endOfWeekDate}</span></p>
          </div>
        </>
      } */}
        <div className="section-header">
          {/* <div>
            <span>This week</span><br />
            <span>{startOfWeekDate} - {endOfWeekDate}</span>
          </div> */}
          <div>
            {currentWeekRecipes && <>
              <div>
                <span>This week</span><br />
                <span>{startOfWeekDate} - {endOfWeekDate}</span>
              </div>
              <span className="recipe-count">
                <span>
                  {`${currentWeekRecipes.length} `}
                </span>
              Recipes
            </span>
            </>
            }
          </div>
        </div>
        {currentWeekRecipes ?
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab label="Recipes" value="1" />
                  <Tab label="Grocery list" value="2" />
                </TabList>
              </Box>
              <TabPanel value="1">
                {/* RECIPES */}
                <ul>
                  {userData && currentWeekRecipes.map((recipe, i) =>
                    <li key={i}>
                      <div>
                        <img
                          src={recipe.img}
                          alt={recipe.name}
                          onError={(e) => {
                            e.target.onerror = null; // Prevent infinite callback loop
                            e.target.src = ''; // Fallback image or leave as is for no image
                          }}
                          width="150"
                          height="150"
                        />
                        <div className="recipe-text">
                          <span>{recipe.name}</span>
                          {
                            recipe.foodPreferences && recipe.foodPreferences.isVegetarian &&
                            <span className="tag vege">Vege</span>
                          }
                        </div>
                      </div>
                      <Link to={`/recipe/${recipe.id}`} className="secondary">
                        View Recipe
                </Link>
                    </li>
                  )}
                </ul>
              </TabPanel>
              <TabPanel value="2">
                {
                  userData && userData.thisWeekGroceryList &&
                  <ThisWeekGroceriesList groceryList={userData.thisWeekGroceryList} />
                }
              </TabPanel>
              <TabPanel value="3">
                Nutritional info
            </TabPanel>
            </TabContext>
          </Box>
          : <MealPlan weekID={weekID} />
        }
      </div>
    </>
  );
}

export default ThisWeekRecipes;
