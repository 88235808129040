import React, { useEffect, useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Modal from 'react-modal';
import { useAppState } from '../context/AppStateContext';
import '../styles/StripePopup.css';
import axios from 'axios';

Modal.setAppElement('#root');

export default function SubscribePopup({ isOpen, onClose }) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useAppState();
  const { userData } = state;
  const [purchaseDetails, setPurchaseDetails] = useState({
    productName: "Weekly Meal Plan Subscription",
    productDescription: "Subscribe for weekly meal plans",
    price: "$2.99",
    currency: "USD"
  });

  useEffect(() => {
    if (!stripe) return;

    const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
    if (!clientSecret) return;

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    setIsLoading(true);

    try {
        const response = await axios.post("https://us-central1-the-weekly-meals.cloudfunctions.net/api/process-subscription", {
            userEmail: userData.email
        });

        const { clientSecret } = response.data;

        const { error, paymentIntent } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // No redirect required, so we pass redirect: 'if_required'
                return_url: '', // Can be an empty string or leave it out
            },
            redirect: 'if_required',
            clientSecret,
        });

        if (error) {
            setMessage(error.message);
        } else if (paymentIntent && paymentIntent.status === "succeeded") {
            setMessage("Payment succeeded!");
            // Additional logic if needed, e.g., updating state, closing modal, etc.
        } else if (paymentIntent && paymentIntent.status === "processing") {
            setMessage("Your payment is processing.");
        } else {
            setMessage("Payment failed. Please try again.");
        }
    } catch (err) {
        console.error('Payment processing error:', err);
        setMessage('An unexpected error occurred. Please try again.');
    } finally {
        setIsLoading(false);
    }
};


  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} overlayClassName="modal-overlay" className="modal">
      <button className="modal-close-button" onClick={onClose}>×</button>
      <div className="modal-content">
        <form id="payment-form" onSubmit={handleSubmit}>
          <div className="purchase-details">
            <h3>Purchase Details</h3>
            <p><strong>Product:</strong> {purchaseDetails.productName}</p>
            <p><strong>Description:</strong> {purchaseDetails.productDescription}</p>
            <div className="price-display">
              <span className="currency">{purchaseDetails.currency}</span>
              <span className="price">{purchaseDetails.price}</span>
            </div>
          </div>

          <PaymentElement id="payment-element" options={{ layout: "tabs" }} />
          <button className="pay-button" disabled={isLoading || !stripe || !elements} id="submit">
            <span id="button-text">{isLoading ? <div className="spinner"></div> : "Pay now"}</span>
          </button>
          {message && <div id="payment-message">{message}</div>}
        </form>
      </div>
    </Modal>
  );
}
