import React, { useEffect, useState } from "react";
import { PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Modal from 'react-modal';
import { useAppState } from '../context/AppStateContext';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import '../styles/StripePopup.css';
import axios from 'axios';

Modal.setAppElement('#root');

export default function StripePopup({ isOpen, onClose }) {
  const stripe = useStripe();
  const elements = useElements();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { state, dispatch } = useAppState();
  const { userData } = state;
  const [paymentType, setPaymentType] = useState("subscription");
  const [currentStep, setCurrentStep] = useState(1);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [isSignUp, setIsSignUp] = useState(true);
  const [error, setError] = useState('');
  const [purchaseDetails, setPurchaseDetails] = useState({
    productName: "Weekly Meal Plan Subscription",
    productDescription: "Subscribe for weekly meal plans",
    price: "$2.99",
    currency: "USD"
  });


  useEffect(() => {
    if (isOpen) {
      setCurrentStep(userData ? 2 : 1);  // Reset the step when modal opens
    }
  }, [isOpen, userData]);

  useEffect(() => {
    if (!stripe) return;

    const clientSecret = new URLSearchParams(window.location.search).get("payment_intent_client_secret");
    if (!clientSecret) return;

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const validatePassword = () => {
    if (password.length < 6) {
      setError('Password should be at least 6 characters long');
      return false;
    }
    if (!/[a-z]/.test(password)) {
      setError('Password should contain at least one lowercase letter');
      return false;
    }
    if (!/[A-Z]/.test(password)) {
      setError('Password should contain at least one uppercase letter');
      return false;
    }
    if (!/\d/.test(password)) {
      setError('Password should contain at least one digit');
      return false;
    }
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return false;
    }
    return true;
  };

  const handleLogin = (e) => {
    e.preventDefault();
    setIsLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        dispatch({ type: 'SET_USER_DATA', payload: userCredential.user });
        setCurrentStep(2); // Proceed to payment step
        setIsLoading(false);
      })
      .catch(err => {
        setError('Failed to log in. Please try again.');
        setIsLoading(false);
      });
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    if (!validatePassword()) {
      setIsLoading(false);
      return;
    }

    try {
      const response = await axios.post('https://us-central1-the-weekly-meals.cloudfunctions.net/api/register', {
        email,
        password,
        firstName,
      });

      // Automatically log in the user after successful registration
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          dispatch({ type: 'SET_USER_DATA', payload: userCredential.user });
          setCurrentStep(2); // Proceed to payment step
          setIsLoading(false);
        })
        .catch(() => {
          setError('An error occurred while logging in');
          setIsLoading(false);
        });
    } catch (error) {
      if (error.response && error.response.status === 500) {
        setError('Email address is already in use.');
      } else if (error.response && error.response.status === 400) {
        setError(error.response.data === 'Email already in use' ? 'Email address is already in use.' : error.response.data);
      } else {
        setError('Network error. Please try again later.');
      }
      setIsLoading(false);
    }
  };

  const handlePaymentTypeChange = (newPaymentType) => {
    setPaymentType(newPaymentType);
    if (newPaymentType === "subscription") {
      setPurchaseDetails({
        productName: "Weekly Meal Plan Subscription",
        productDescription: "Subscribe for weekly meal plans",
        price: "$2.99",
        currency: "USD"
      });
    } else {
      setPurchaseDetails({
        productName: "Weekly Meal Plan",
        productDescription: "One-time purchase for weekly meal plan",
        price: "$3.99",
        currency: "USD"
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!stripe || !elements) return;
  
    setIsLoading(true);
  
    // Submit the form
    const { error: submitError } = await elements.submit();
  
    if (submitError) {
      setMessage(submitError.message);
      setIsLoading(false);
      return;
    }
  
    const backendEndpoint = paymentType === "subscription"
      ? "https://us-central1-the-weekly-meals.cloudfunctions.net/api/process-subscription"
      : "https://us-central1-the-weekly-meals.cloudfunctions.net/api/process-one-time-payment";
  
    const response = await fetch(backendEndpoint, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ userEmail: userData.email })
    });
  
    const responseData = await response.json();
    const { subscriptionId, clientSecret } = responseData;
  
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: { return_url: "https://the-weekly-meals.web.app/meal-planner/recipes" },
      clientSecret,
    });
  
    if (error) {
      setMessage(error.message);
    } else {
      setMessage('Payment succeeded! Your subscription is being processed.');
    }
  
    setIsLoading(false);
  };  
  
  const renderStepContent = () => {
    if (currentStep === 1) {
      return (
        <div>
          <h2>{isSignUp ? "Sign Up" : "Login"} to Proceed</h2>
          {error && <div className="auth__error">{error}</div>}
          <form onSubmit={isSignUp ? handleSignUp : handleLogin} className="form">
            <input
              type="email"
              value={email}
              placeholder="Enter your email"
              required
              onChange={(e) => setEmail(e.target.value)}
              className="input"
            />
            {isSignUp && (
              <input
                type="text"
                value={firstName}
                placeholder="Enter your first name"
                required
                onChange={(e) => setFirstName(e.target.value)}
                className="input"
              />
            )}
            <input
              type="password"
              value={password}
              placeholder="Enter your password"
              required
              onChange={(e) => setPassword(e.target.value)}
              className="input"
            />
            {isSignUp && (
              <input
                type="password"
                value={confirmPassword}
                placeholder="Confirm your password"
                required
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="input"
              />
            )}
            <button type="submit" className="button" disabled={isLoading}>
              {isLoading ? <div className="spinner"></div> : (isSignUp ? "Sign Up" : "Login")}
            </button>
          </form>
          <p>
            {isSignUp ? (
              <span>Already have an account? <button onClick={() => setIsSignUp(false)} className="link-button">Login</button></span>
            ) : (
              <span>Don't have an account? <button onClick={() => setIsSignUp(true)} className="link-button">Sign up</button></span>
            )}
          </p>
        </div>
      );
    } else if (currentStep === 2) {
      return (
        <form id="payment-form" onSubmit={handleSubmit}>
          <div className="payment-type-toggle">
            <button
              type="button"
              className={paymentType === "subscription" ? "active" : ""}
              onClick={() => handlePaymentTypeChange("subscription")}
            >
              Subscription
            </button>
            <button
              type="button"
              className={paymentType === "oneTime" ? "active" : ""}
              onClick={() => handlePaymentTypeChange("oneTime")}
            >
              One-time Purchase
            </button>
          </div>

          <div className="purchase-details">
            <h3>Purchase Details</h3>
            <p><strong>Product:</strong> {purchaseDetails.productName}</p>
            <p><strong>Description:</strong> {purchaseDetails.productDescription}</p>
            <div className="price-display">
              <span className="currency">{purchaseDetails.currency}</span>
              <span className="price">{purchaseDetails.price}</span>
            </div>
          </div>

          <PaymentElement id="payment-element" options={{ layout: "tabs" }} />
          <button className="pay-button" disabled={isLoading || !stripe || !elements} id="submit">
            <span id="button-text">{isLoading ? <div className="spinner"></div> : "Pay now"}</span>
          </button>
          {message && <div id="payment-message">{message}</div>}
        </form>
      );
    }
  };

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} overlayClassName="modal-overlay" className="modal">
      <button className="modal-close-button" onClick={onClose}>×</button>
      <div className="modal-content">
        {renderStepContent()}
      </div>
    </Modal>
  );
}
