import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAppState } from '../../context/AppStateContext';
import axios from 'axios';
import '../../styles/Success.css';
import Loader from '../Loaders';

export default function SuccessPage() {
    const { state } = useAppState();
    const [isLoading, setIsLoading] = useState(false);

    const generatePDF = async () => {
        setIsLoading(true); // Start loading
        try {
            const response = await axios.post('https://us-central1-the-weekly-meals.cloudfunctions.net/api/generate-pdf', state.generatedRecipes, {
                responseType: 'blob'
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'meal-plan.pdf');
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error generating PDF:', error);
        } finally {
            setIsLoading(false); // Stop loading
        }
    };

    const printGroceryList = () => {
        const ulContent = document.querySelector('.grocery-list ul').innerHTML;
        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
            <html>
            <head>
                <title>Grocery List</title>
                <style>
                    * {
                        font-family: 'Inter', sans-serif;
                    }
                </style>
            </head>
            <body>${ulContent}</body>
            </html>
        `);
        printWindow.document.close();
        printWindow.print();
    };

    return (
        <div className="success-page">
            {isLoading && <Loader />} {/* Display loader when PDF is generating */}
            {!isLoading && (
                <>
                    <svg xmlns="http://www.w3.org/2000/svg" width="112.821" height="112.821" viewBox="0 0 112.821 112.821" className="inbox">
                        <g id="Page-1" transform="translate(0)">
                            <g id="Dribbble-Light-Preview" transform="translate(0)">
                            <g id="icons">
                                <path id="inbox_done_round-_1543_" data-name="inbox_done_round-[#1543]" d="M284.458,920.823a5.64,5.64,0,1,1,7.976-7.977l3.988,3.989,11.97-11.965a5.64,5.64,0,1,1,7.977,7.976l-19.947,19.941-11.965-11.964ZM339.9,935.41v-17.4l5.641,17.4Zm5.641,39.014c0,3.119-2.521,6.115-5.641,6.115H260.923c-3.114,0-5.641-3-5.641-6.115V946.693h16.923c3.12,0,5.641,2.053,5.641,5.167V957.5c0,6.233,5.054,11.755,11.282,11.755h22.564c6.233,0,11.282-5.522,11.282-11.755V951.86c0-3.114,2.527-5.167,5.641-5.167h16.923Zm-84.616-56.411v17.4h-5.641Zm11.282-22.564c0-3.114,2.527-5.167,5.641-5.167h45.128c3.12,0,5.641,2.053,5.641,5.167V935.41h-5.641c-6.228,0-11.282,4.581-11.282,10.809v5.641c0,3.119-2.522,6.115-5.641,6.115H294.77c-3.114,0-5.641-3-5.641-6.115v-5.641c0-6.228-5.049-10.809-11.282-10.809h-5.641Zm73.334,6.115H339.9V889.808c0-6.228-5.049-10.808-11.282-10.808H272.205c-6.228,0-11.282,4.58-11.282,10.808v11.756h-5.641L244,934.937v45.128c0,6.233,5.054,11.756,11.282,11.756h90.257c6.233,0,11.282-5.523,11.282-11.756V934.937Z" transform="translate(-244 -879)" fill="#84b74e" fillRule="evenodd"/>
                            </g>
                            </g>
                        </g>
                    </svg>
                    <h1>Success</h1>
                    <p>Your meal plan is on its way to your inbox!</p>
                    <div className="button-group">
                        {/* <button onClick={generatePDF} disabled={isLoading} className="download-pdf-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width="37.93" height="37.931" viewBox="0 0 37.93 37.931"><path d="M31.97,30.7H27.635a1.625,1.625,0,0,1,0-3.251H31.97a2.71,2.71,0,0,0,2.709-2.709V16.066a2.71,2.71,0,0,0-2.709-2.709H5.96a2.71,2.71,0,0,0-2.709,2.709v8.671A2.71,2.71,0,0,0,5.96,27.446H10.3a1.625,1.625,0,0,1,0,3.251H5.96A5.96,5.96,0,0,1,0,24.737V16.066a5.96,5.96,0,0,1,5.96-5.96H31.97a5.961,5.961,0,0,1,5.96,5.96v8.671a5.961,5.961,0,0,1-5.96,5.96" transform="translate(0 -1.435)" fill="#fff"/><path d="M29.071,11.921A1.647,1.647,0,0,1,27.445,10.3V3.252H13.357V10.3a1.625,1.625,0,1,1-3.251,0V2.709A2.71,2.71,0,0,1,12.815,0H27.988A2.71,2.71,0,0,1,30.7,2.709V10.3a1.647,1.647,0,0,1-1.626,1.626" transform="translate(-1.435)" fill="#fff"/><path d="M27.987,40.8H12.814A2.71,2.71,0,0,1,10.1,38.093V22.92a2.71,2.71,0,0,1,2.709-2.709H27.987A2.708,2.708,0,0,1,30.7,22.92V38.093A2.708,2.708,0,0,1,27.987,40.8M13.357,37.55H27.445V23.462H13.357Z" transform="translate(-1.435 -2.871)" fill="#fff"/></svg>
                            Print Grocery List
                        </button> */}

                        <button onClick={generatePDF} disabled={isLoading} className="download-pdf-link">
                        <svg xmlns="http://www.w3.org/2000/svg" width="37.93" height="37.931" viewBox="0 0 37.93 37.931"><path d="M31.97,30.7H27.635a1.625,1.625,0,0,1,0-3.251H31.97a2.71,2.71,0,0,0,2.709-2.709V16.066a2.71,2.71,0,0,0-2.709-2.709H5.96a2.71,2.71,0,0,0-2.709,2.709v8.671A2.71,2.71,0,0,0,5.96,27.446H10.3a1.625,1.625,0,0,1,0,3.251H5.96A5.96,5.96,0,0,1,0,24.737V16.066a5.96,5.96,0,0,1,5.96-5.96H31.97a5.961,5.961,0,0,1,5.96,5.96v8.671a5.961,5.961,0,0,1-5.96,5.96" transform="translate(0 -1.435)" fill="#fff"/><path d="M29.071,11.921A1.647,1.647,0,0,1,27.445,10.3V3.252H13.357V10.3a1.625,1.625,0,1,1-3.251,0V2.709A2.71,2.71,0,0,1,12.815,0H27.988A2.71,2.71,0,0,1,30.7,2.709V10.3a1.647,1.647,0,0,1-1.626,1.626" transform="translate(-1.435)" fill="#fff"/><path d="M27.987,40.8H12.814A2.71,2.71,0,0,1,10.1,38.093V22.92a2.71,2.71,0,0,1,2.709-2.709H27.987A2.708,2.708,0,0,1,30.7,22.92V38.093A2.708,2.708,0,0,1,27.987,40.8M13.357,37.55H27.445V23.462H13.357Z" transform="translate(-1.435 -2.871)" fill="#fff"/></svg>
                        Download PDF
                        </button>
                    </div>
                    {/* <Link to="/my-meal-plans">My meal plans</Link> */}
                </>
            )}
        </div>
    );
}
