import React, { useState, useEffect } from 'react';
import RecipeCard from '../RecipeCard.jsx';
import { Link } from 'react-router-dom';
import { useAppState } from '../../context/AppStateContext';
import axios from 'axios'; // Make sure to import axios
import '../../styles/MyMealPlans.css';
import { format, startOfWeek, endOfWeek } from 'date-fns';

export default function MyMealPlans() {
  const { state } = useAppState();
  const { userData } = state;
  const [mealPlans, setMealPlans] = useState({});
  const [isLoading, setIsLoading] = useState(false); // Define the loading state

  useEffect(() => {
    if (userData && userData.recipes) {
      setMealPlans(userData.recipes);
    }
  }, [userData]);

  const handleDownloadPdf = async (recipes) => {
    setIsLoading(true); // Start loading
    try {
        // Send the selected recipes to the PDF generation endpoint
        const response = await axios.post('https://us-central1-the-weekly-meals.cloudfunctions.net/api/generate-pdf', recipes, {
            responseType: 'blob'
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'meal-plan.pdf');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up the DOM
    } catch (error) {
        console.error('Error generating PDF:', error);
    } finally {
        setIsLoading(false); // Stop loading
    }
  };

  return (
    <div className="meal-plans">
      <svg xmlns="http://www.w3.org/2000/svg" width="67.571" height="67.571" viewBox="0 0 67.571 67.571"><path d="M67.571,67.571H0V8.446H14.077V0h5.631V8.446H47.863V0h5.631V8.446H67.571ZM5.631,61.94H61.94V14.077H5.631v8.446H61.94v5.631H5.631Zm47.863-8.446H25.339V47.863H53.494Zm-33.785,0H14.077V47.863h5.631ZM53.494,42.232H25.339V36.6H53.494Zm-33.785,0H14.077V36.6h5.631Z" fill="#2B3231"/></svg>
      <h1 className="meal-plans-title">My Meal Plans</h1>
      {Object.keys(mealPlans).map((week) => {
        // Gather all recipes across categories
        const recipes = [].concat(
          ...['salads', 'main', 'sweets', 'breakfast', 'snacks'].map(category => mealPlans[week][category] || [])
        );
        
        return (
          <div key={week} className="week-section">
            <div className="week-header">
              <div className="week-actions">
                <button onClick={() => handleDownloadPdf(mealPlans[week])} className="download-pdf-link">
                  {isLoading ? 'Downloading...' : 'Download PDF'}
                </button>
              </div>
            </div>
            <div className="week-recipes">
              {recipes.map((recipe, index) => (
                <Link to={recipe.url} className="recipe-card" target="blank">            
                {recipe.img && (
                  <div 
                    className="img-container" 
                    style={{ backgroundImage: `url(${recipe.img})` }}
                  ></div>
                )}
                <div className="content">
                    <h3>{recipe.name}</h3>
                    {
                      recipe.containsIngredient && <span className="contains-ingredient">Contains {recipe.containsIngredient}</span>
                    }
                    {
                      recipe.foodPreferences?.isVegetarian && !recipe.foodPreferences?.isVegan && 
                      <p className="tag vege">Vege</p>
                    }
                    {
                      recipe.foodPreferences?.isVegan && 
                      <p className="tag vegan">Vegan</p>
                    }
                    <div className="infos">
                        <div><span>{recipe.ingredients?.length}</span>ingr.</div>
                        <div><span>{recipe.timePrep}</span>min.</div>
                        <div><span>{recipe.calories}</span>cal.</div>
                        <div><span>2</span>serv.</div>
                        <div><span>${recipe.approximateCost}</span></div>
                    </div>
                </div>
            </Link>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}
