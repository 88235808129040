import React from 'react';
import '../styles/recipeCard.css'
import { Link } from 'react-router-dom';

export default function RecipeCard({id,name, img, url, ingredients,instructions, approximateCost,timePrep,calories,nutritionalInfo,perfectFor, containsIngredient, foodPreferences}) {  
  return (
        <Link to={url} className="recipe-card" target="blank">            
            <div className="img-container">
            { img && (
              <img
                src={img}
                alt={name}
                width="200"
                height="200"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '';
                }}
              />
              )
            }
            </div>
            <div className="content">
                <h3>{name}</h3>
                {
                  containsIngredient && <span className="contains-ingredient">Contains {containsIngredient}</span>
                }
                {
                  foodPreferences?.isVegetarian && !foodPreferences?.isVegan && 
                  <p className="tag vege">Vege</p>
                }
                {
                  foodPreferences?.isVegan && 
                  <p className="tag vegan">Vegan</p>
                }
                <div className="infos">
                    <div><span>{ingredients?.length}</span>ingr.</div>
                    <div><span>{timePrep}</span>min.</div>
                    <div><span>{calories}</span>cal.</div>
                    <div><span>2</span>serv.</div>
                    <div><span>${approximateCost}</span></div>
                </div>
                {/* <div className="link-container">
                     <Link to={`/recipe/${id}`}>View recipe details</Link>
                </div> */}
            </div>
        </Link>
    )
}
