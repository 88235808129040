import { useState } from 'react';
import { Link } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase';
import { useNavigate } from 'react-router-dom';
import '../styles/Account.css';
import Footer from '../components/Footer.jsx'

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const login = e => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        if (location.pathname.includes('/meal-planner/log-in')) {
          navigate('/meal-planner/grocery-list');
        } else {
          navigate('/');
        }
      })
      .catch(err => {
        let errorMessage = 'An error occurred while signing in';
        switch (err.code) {
          case 'auth/invalid-email':
            errorMessage = 'Invalid email address';
            break;
          case 'auth/user-disabled':
            errorMessage = 'Your account has been disabled';
            break;
          case 'auth/user-not-found':
            errorMessage = 'User not found';
            break;
          case 'auth/wrong-password':
            errorMessage = 'Incorrect password';
            break;
          default:
            errorMessage = err.message || errorMessage;
            break;
        }
        setError(errorMessage);
      });
  };

  return (
    <>
      <div className='center'>
        <div className='auth'>
          <h1>Log in</h1>
          {error && <div className='auth__error'>{error}</div>}
          <form onSubmit={login} name='login_form'>
            <input
              type='email'
              value={email}
              required
              placeholder='Enter your email'
              onChange={e => setEmail(e.target.value)}
            />
            <input
              type='password'
              value={password}
              required
              placeholder='Enter your password'
              onChange={e => setPassword(e.target.value)}
            />
            <button type='submit'>Login</button>
          </form>
          <p>
            <Link to='/account/forgot-password'>Forgot Password?</Link>
          </p>
          <p>
            {location.pathname.includes('/meal-planner/log-in') ? (
            <span>
              Don't have an account? <Link to='/meal-planner/sign-up'>Create one here</Link>
            </span>
          ) : (
            <span>
              Don't have an account? <Link to='/account/register'>Create one here</Link>
            </span>
          )}
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Login;
