import React, { useEffect, useState } from 'react';
import { useAppState } from '../context/AppStateContext';
import { Link } from 'react-router-dom';
import '../styles/AllRecipesList.css';

function AllRecipes() {
    const { state } = useAppState();
    const { allRecipes } = state;
    const [combinedIngredients, setCombinedIngredients] = useState([]);
    const [recipeIds, setRecipeIds] = useState([]);


    console.log(allRecipes)
    const categories = ['breakfast', 'salads', 'main', 'sweets', 'snacks'];

    useEffect(() => {
        categories.forEach((category) => {
            if (allRecipes[category]?.length > 0) {
                const ids = allRecipes[category].map(recipe => recipe.id);
                setRecipeIds(prevIds => [...prevIds, ...ids]);
            }
        });
    }, [allRecipes]);

    useEffect(() => {
        if (categories.some(category => allRecipes[category]?.length > 0)) {
            const combined = combineIngredientsWithAmounts(allRecipes);
            setCombinedIngredients(combined);
        }
    }, [allRecipes]);

    function combineIngredientsWithAmounts(recipes) {
        const combinedIngredients = {};

        categories.forEach((category) => {
            recipes[category]?.forEach(recipe => {
                recipe.ingredients.forEach(ingredient => {
                    const key = `${ingredient.name}-${ingredient.unit}`;
                    if (combinedIngredients[key]) {
                        const currentAmount = parseFloat(ingredient.amount);
                        const existingAmount = parseFloat(combinedIngredients[key].amount);

                        if (!isNaN(currentAmount) && !isNaN(existingAmount)) {
                            combinedIngredients[key].amount = existingAmount + currentAmount;
                        } else {
                            combinedIngredients[key].amount = `${combinedIngredients[key].amount}, ${ingredient.amount}`;
                        }
                    } else {
                        combinedIngredients[key] = {
                            amount: ingredient.amount,
                            unit: ingredient.unit,
                            name: ingredient.name,
                        };
                    }
                });
            });
        });

        return Object.values(combinedIngredients);
    }

    console.log('allRecipes:', allRecipes);  // Debugging line
    console.log('combinedIngredients:', combinedIngredients);  // Debugging line
    console.log('recipeIds:', recipeIds);  // Debugging line

    return (
        <div className="recipe-list">
            {categories.map(category => (
                <div key={category}>
                    <ul>
                        {allRecipes[category]?.length > 0 && allRecipes[category].map((recipe, i) => (
                            <li
                                key={i}
                                className={`recipe-item ${!recipe.img ? 'no-image' : ''}`}
                            >
                                <Link to={`${recipe.url}`} target="_blank" className="recipe-link">
                                <h2>{category}</h2>
                                <h2>{i}</h2>
                                    <img
                                        src={recipe.img}
                                        alt={recipe.name}
                                        className="recipe-image"
                                    />
                                    <div className="recipe-info">
                                        <h2 className="recipe-name">{recipe.name}</h2>
                                    </div>
                                </Link>
                                {recipe.instructions && (
                                    <div className="instructions">
                                        <h3>Instructions</h3>
                                        <ul className="recipe-instructions">{recipe.instructions.map((instruction, i) => <li key={i}>{instruction}</li>)}</ul>
                                    </div>
                                )}
                                {recipe.ingredients && (
                                    <div className="ingredient-list">
                                        <h3>Ingredients</h3>
                                        <ul className="ingredient-details">
                                            {recipe.ingredients.map((ingredient, index) => (
                                                <li key={index}>
                                                    <span>{ingredient.amount}</span> <span>{ingredient.unit}</span> - <span>{ingredient.name}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
}

export default AllRecipes;