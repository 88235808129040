import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { getISOWeek, startOfWeek, endOfWeek, format } from 'date-fns';
import '../styles/UserDashboard.css'

import ThisWeekRecipes from '../components/UserDashboard/ThisWeekRecipes'
import ThisWeekGroceriesList from '../components/UserDashboard/ThisWeekGroceriesList'
import PreviousWeeks from '../components/UserDashboard/PreviousWeeks'
import MyAccount from '../components/UserDashboard/MyAccount'
import MyMealPlans from '../components/UserDashboard/MyMealPlans'
import ProPlan from '../components/UserDashboard/ProPlan'
import { useAppState } from '../context/AppStateContext';

function UserDashboard () {
  const { state } = useAppState();
  const { allRecipes , currentUser, token, userData } = state;
  
  const [startOfWeekDate, setStartOfWeekDate] = useState(null);
  const [endOfWeekDate, setEndOfWeekDate] = useState(null);
  const [currentWeekRecipes, setCurrentWeekRecipes] = useState(null)
  const [weekID, setWeekID] = useState(null)


  useEffect(() => {
    const today = new Date();
    const year = today.getFullYear();
    const isoWeekNumber = getISOWeek(today);

    // const currentWeekId = `${year}-W${isoWeekNumber}`;
    const currentWeekId = `2024-W14`;

    setWeekID(currentWeekId);
  }, []);


    useEffect(() => {
      const today = new Date();

      // Find the beginning and end dates of the current ISO week (Monday to Sunday)
      const startOfWeekDt = startOfWeek(today, { weekStartsOn: 1 }); // Monday is the start of the week
      const endOfWeekDt = endOfWeek(today, { weekStartsOn: 1 }); // Sunday is the end of the week

      const formattedStartDate = format(startOfWeekDt, "MMMM do yyyy");
      const formattedEndDate = format(endOfWeekDt, "MMMM do yyyy");

      setStartOfWeekDate(formattedStartDate);
      setEndOfWeekDate(formattedEndDate);
    }, [])

    useEffect(() => {
      if (weekID && userData && userData.recipes && allRecipes.length > 0) {
        const currentWeekRecipesID = userData.recipes[weekID];

        if (!currentWeekRecipesID) {
          // User doesn't have a meal plean for this week
          return;
        }

        let recipesOfTheWeekData = [];
        currentWeekRecipesID.map((recipeId) => allRecipes.find((recipe) => {
          if (recipe && recipe.id === recipeId) {
            recipesOfTheWeekData.push(recipe)
          }
        }));

        console.log(recipesOfTheWeekData)
        
        setCurrentWeekRecipes(recipesOfTheWeekData)
      }
    }, [weekID, userData, allRecipes]);


  return (
    <div className="user-dashboard">
      {
        allRecipes && weekID ? 
        <div className="dashboard">
          <div className="tab-content">
            <Routes>
                  <Route
                  path="/*"
                  element={<ThisWeekRecipes currentWeekRecipes={currentWeekRecipes} startOfWeekDate={startOfWeekDate} endOfWeekDate={endOfWeekDate} weekID={weekID} userData={userData} />}
                  />
                  <Route
                  path="/my-meal-plans"
                  element={<MyMealPlans />}
                  />
                  <Route
                  path="/this-week-groceries-list"
                  element={<ThisWeekGroceriesList currentWeekRecipes={currentWeekRecipes} />}
                  />
                  <Route
                  path="/previous-weeks"
                  element={<PreviousWeeks userData={userData} weekID={weekID} />}
                  />
                  <Route
                  path="/my-account"
                  element={<MyAccount userData={userData} weekID={weekID} />}
                  />
                  <Route
                  path="/subscription"
                  element={<ProPlan userData={userData} weekID={weekID} />}
                  />
              </Routes>
          </div>
        </div> : ''
      }
  </div>
  );
};

export default UserDashboard;
