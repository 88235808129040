import React, { useState } from 'react';
import axios from 'axios';
import '../styles/Support.css';
import Footer from '../components/Footer';

function Support() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);  // Set loading state to true when form submission starts

    axios({
      method: "POST",
      url: "https://us-central1-the-weekly-meals.cloudfunctions.net/api/sendEmailToSupport",
      data: formData
    })
      .then((response) => {
        if (response.status === 200 && response.data.message === "Email sent successfully") {
          setSuccessMessage("Thanks for reaching out!\nOur team will contact you as soon as possible to assist with your request.");
          setErrorMessage('');  // Clear any previous error message
          setFormData({
            name: '',
            email: '',
            message: ''
          });
        } else {
          setErrorMessage("Message failed to send. Please try again.");
          setSuccessMessage('');  // Clear any previous success message
        }
      })
      .catch((error) => {
        console.error("Error sending message:", error);
        setErrorMessage("Error sending message. Please try again later.");
        setSuccessMessage('');  // Clear any previous success message
      })
      .finally(() => {
        setIsLoading(false);  // Set loading state to false once form submission is complete
      });
  };

  return (
    <>
      <div className="support-card">
        <div className="support-header">
        <svg xmlns="http://www.w3.org/2000/svg" width="68.577" height="55.043" viewBox="0 0 68.577 55.043">
          <g id="support-svgrepo-com" transform="translate(0.83 0.743)">
            <path id="Path_62" data-name="Path 62" d="M58.479,24.542a24.979,24.979,0,0,0-49.953.152A10.188,10.188,0,0,0-.05,34.6a.794.794,0,0,0-.03.218A10.328,10.328,0,0,0,10.187,45.182h7.6a18.946,18.946,0,0,0,34.5-8.065l1.269-9.427a20.242,20.242,0,1,0-40.123,0l1.269,9.427a18.793,18.793,0,0,0,2.075,6.391H13.307a21.849,21.849,0,0,1-1.667-5.98L10.372,28.1a23.264,23.264,0,0,1-.191-2.378.8.8,0,0,0,.089-.427.786.786,0,0,0-.084-.273,23.311,23.311,0,0,1,46.621-.117.889.889,0,0,0-.077.295.824.824,0,0,0,.072.415,23.864,23.864,0,0,1-.2,2.529l-1.269,9.474a19.264,19.264,0,0,1-1.8,6.313.837.837,0,0,0,.731,1.247h2.6A10.3,10.3,0,0,0,67,34.7,10.184,10.184,0,0,0,58.479,24.542ZM16.351,36.893l-1.269-9.426a18.57,18.57,0,1,1,36.809,0l-1.269,9.427a17.267,17.267,0,0,1-30.767,8.288h5.3a.646.646,0,0,0,.082-.017,4.191,4.191,0,0,0,4.1,3.364h6.694a4.184,4.184,0,0,0,0-8.368H29.342a4.191,4.191,0,0,0-4.1,3.364.646.646,0,0,0-.082-.017H18.709A17.113,17.113,0,0,1,16.351,36.893Zm10.48,7.452a2.513,2.513,0,0,1,2.51-2.51h6.694a2.51,2.51,0,0,1,0,5.021H29.342A2.513,2.513,0,0,1,26.831,44.345Zm-15.337-.837H10.187A8.65,8.65,0,0,1,1.6,34.955a.885.885,0,0,0,.027-.209,8.524,8.524,0,0,1,6.937-8.361c.037.648.069,1.3.156,1.945l1.269,9.424A23.752,23.752,0,0,0,11.495,43.509Zm45.369,0H55.58a24.324,24.324,0,0,0,1.409-5.663l1.269-9.474c.1-.711.162-1.424.2-2.134a8.739,8.739,0,0,1-1.59,17.271Z" transform="translate(0 0)" fill="#1c1c1c" stroke="#616161" strokeWidth="1.5"/>
          </g>
          </svg>
          <h2>We're here to help</h2>
          <p>If you need assistance, please contact our support team.</p>
        </div>
        <form onSubmit={handleSubmit} className="support-form">
          <div className="input-group">
            <input 
              type="text" 
              name="name" 
              value={formData.name} 
              onChange={handleChange} 
              placeholder="Name" 
              required 
            />
            <input 
              type="email" 
              name="email" 
              value={formData.email} 
              onChange={handleChange} 
              placeholder="Enter Mail" 
              required 
            />
          </div>
          <div className="input-group">
            <textarea 
              name="message" 
              value={formData.message} 
              onChange={handleChange} 
              placeholder="How we can help?" 
              required 
            />
          </div>
          <button type="submit" className="submit-button" disabled={isLoading}>
            {isLoading ? "Sending..." : "Submit"}
          </button>
        </form>
        {successMessage && <p className="success-message">{successMessage}</p>}
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
      <Footer />
    </>
  );
}

export default Support;
