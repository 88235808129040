import React, { useEffect, useState } from 'react';

export default function ThisWeekGroceriesList({ groceryList }) {
    const [list, setList] = useState(groceryList);
    const [totalItemsToBuy, setTotalItemsToBuy] = useState(0);

    const printGroceryList = () => {
      const ulContent = document.querySelector('.grocery-list .list-container').innerHTML;
      const printWindow = window.open('', '_blank');
      printWindow.document.write(`
          <html>
          <head>
              <title>Grocery List</title>
              <style>
                  * {
                      font-family: "Montserrat", sans-serif;
                  }
              </style>
          </head>
          <body>${ulContent}</body>
          </html>
      `);
      printWindow.document.close();
      printWindow.print();
  };
  
  useEffect(() => {
    if (list) {
      let count = 0;
      Object.values(list)?.forEach(category => {
        category?.forEach(item => {
          if (!item.isCrossedOut) {
            // If the item is not crossed out, increment the count
            count++;
          }
        });
      });
      setTotalItemsToBuy(count);
    }
  }, [list]);

  

    // const toggleCrossedOut = (aisleName, itemIndex) => {
    //     const updatedList = list.map(aisleObj => {
    //         if (aisleObj.aisle === aisleName) {
    //             // Found the correct aisle, now map through its items to update the crossedOut status
    //             return {
    //                 ...aisleObj,
    //                 items: aisleObj.items.map((item, idx) => {
    //                     if (idx === itemIndex) {
    //                         // Toggle the crossedOut status for the item
    //                         return { ...item, crossedOut: !item.crossedOut };
    //                     }
    //                     return item;
    //                 })
    //             };
    //         }
    //         return aisleObj;
    //     });

    //     setList(updatedList);
    // };
    const toggleCrossedOut = (aisle, index) => {
      const updatedList = { ...groceryList };
      updatedList[aisle][index].isCrossedOut = !updatedList[aisle][index].isCrossedOut;
      setTotalItemsToBuy(prevCount =>
        prevCount + (updatedList[aisle][index].isCrossedOut ? -1 : 1)
      );
      // Update state with the modified list
      setList(updatedList);
    };

    return (
        <div className="grocery-list">
          {/* <p>{totalItemsToBuy} ingredients to buy</p> */}
          <button onClick={() => printGroceryList()}>Print Grocery List</button>

        <div className="list-container">
        {list && Object.entries(list).map(([aisle, items]) => (
          <div key={aisle}>
            <h3>{aisle}</h3>
            <ul>
              {items.map((item, index) => (
                <li
                  key={`${aisle}-${index}`}
                  style={{ textDecoration: item.isCrossedOut ? 'line-through' : 'none' }}
                  onClick={() => toggleCrossedOut(aisle, index)}
                >
                  {`${item.amount || ''} ${item.unit || ''} ${item.name}`}
                </li>
              ))}
            </ul>
          </div>
        ))}
        </div>
      </div>
    );
}
