import React, { useEffect, useState, useRef } from 'react';
import { Routes, Route } from 'react-router-dom';
import axios from 'axios';
import { useAppState } from '../context/AppStateContext';
import FoodPreferences from '../components/MealPlanner/FoodPreferences.jsx';
import GroceryList from '../components/MealPlanner/GroceryList.jsx';
import RecipeSelection from '../components/MealPlanner/RecipeSelection.jsx';
import { getISOWeek } from 'date-fns';
import AccountCreation from '../pages/AccountCreation.jsx';
import Login from '../pages/Login.jsx';
import Success from '../components/MealPlanner/Success.jsx';
import '../styles/MealPlan.css';

// Custom hook to store the previous value of a state variable
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export default function UpdatedComponent() {
  const { state, setCurrentWeekGeneratedCombination, setPreferences } = useAppState();
  const { userData } = state;
  const [groceryListPerAisle, setGroceryListPerAisle] = useState(null);
  const [lastFourWeeksRecipes, setLastFourWeeksRecipes] = useState([]);
  const prevPreferences = usePrevious(state.preferences);
  const [loading, setLoading] = useState(true);
  const [recipesByCategory, setRecipesByCategory] = useState({});
  const [weekID, setWeekID] = useState(null);
  const [explanations, setExplanations] = useState({});
  const [categoryLoading, setCategoryLoading] = useState({});

  useEffect(() => {
    // Set the default weekID
    const today = new Date();
    const year = today.getFullYear();
    const isoWeekNumber = getISOWeek(today);
    const defaultWeekID = `${year}-W${isoWeekNumber}`;
    setWeekID(defaultWeekID);
  }, []);
  
  // useEffect(() => {
  //   console.log('prevpreferences',prevPreferences)
  //   console.log('state.preferences', state.preferences)
  //   if (prevPreferences && state.preferences) {
  //     const categories = ['breakfast', 'salads', 'main', 'snacks', 'sweets'];
  
  //     categories.forEach(category => {
  //       if (!objectsAreEqualShallow(prevPreferences[category], state.preferences[category])) {
  //         regenerateCategory(category);
  //       }
  //     });
  //   }
  // }, [prevPreferences, state.preferences]);

  function objectsAreEqualShallow(obj1 = {}, obj2 = {}) {
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false;
    }
  
    for (let key in obj1) {
      if (typeof obj1[key] === 'object' && obj1[key] !== null) {
        if (!objectsAreEqualShallow(obj1[key], obj2[key])) {
          return false;
        }
      } else if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
  
    return true;
  }

  // Function to generate the grocery list by aisle
  function generateGroceryListPerAisle() {
    const groceryList = {};

    function convertToNumber(amount) {
      if (typeof amount === 'string' && amount.includes('/')) {
        const [numerator, denominator] = amount.split('/').map(Number);
        return numerator / denominator;
      }
      return parseFloat(amount) || 0;
    }

    function roundToTwoDecimalPlaces(num) {
      return Math.round(num * 100) / 100;
    }

    function convertToFraction(decimal) {
      const fractions = {
        0.25: '1/4',
        0.33: '1/3',
        0.5: '1/2',
        0.66: '2/3',
        0.75: '3/4',
      };
      const rounded = roundToTwoDecimalPlaces(decimal);
      return fractions[rounded] || rounded.toString();
    }

    if (state.generatedRecipes && typeof state.generatedRecipes === 'object') {
      Object.keys(state.generatedRecipes).forEach((meal) => {
        const mealRecipes = state.generatedRecipes[meal];

        if (Array.isArray(mealRecipes)) {
          mealRecipes.forEach((recipe) => {
            recipe.ingredients?.forEach((ingredient) => {
              const { name, amount, aisle } = ingredient;
              const numericAmount = convertToNumber(amount);

              if (!groceryList[aisle]) {
                groceryList[aisle] = [];
              }

              const existingIngredient = groceryList[aisle].find(
                (existingIngredient) => existingIngredient.name === name
              );

              if (existingIngredient) {
                const newAmount = roundToTwoDecimalPlaces(
                  convertToNumber(existingIngredient.amount) + numericAmount
                );
                if (!isNaN(newAmount)) {
                  existingIngredient.amount = newAmount;
                } else {
                  console.warn(`Invalid amount for ingredient "${name}"`);
                }
              } else {
                groceryList[aisle].push({ ...ingredient, amount: numericAmount, isCrossedOut: false });
              }
            });
          });
        } else {
          console.warn(`Expected array for ${meal} but got ${typeof mealRecipes}`);
        }
      });
    } else {
      console.error("state.generatedRecipes is not defined or not an object.");
    }

    Object.keys(groceryList).forEach((aisle) => {
      groceryList[aisle].forEach((ingredient) => {
        if (ingredient.amount !== "" && !isNaN(ingredient.amount)) {
          ingredient.amount = convertToFraction(ingredient.amount);
        }
      });
    });

    setGroceryListPerAisle(groceryList);
  }
  
  // Function to regenerate recipes for a specific category
  const regenerateCategory = async (category) => {
    setCategoryLoading((prev) => ({ ...prev, [category]: true }));
    const membershipStatus = userData?.membershipStatus || 'free';
    
    try {
      const response = await fetchRecipesForCategory(category, state.preferences, membershipStatus, lastFourWeeksRecipes);
  
      const newRecipesByCategory = { ...recipesByCategory };
      newRecipesByCategory[category] = processRecipes({ [category]: response.chosenRecipes }, state.allRecipes)[category];
  
      setRecipesByCategory(newRecipesByCategory);
      setExplanations(prev => ({ ...prev, [category]: response.explanation }));
    } catch (error) {
      console.error(`Error regenerating recipes for ${category}:`, error);
    } finally {
      setCategoryLoading((prev) => ({ ...prev, [category]: false }));
    }
  };

  // Fetches recipes with preferences
  const getRecipesWithPreferences = async () => {
    const membershipStatus = userData?.membershipStatus || 'free';
    const categories = ['breakfast', 'salads', 'main', 'snacks', 'sweets'];

    setLoading(true);

    try {
      const fetchPromises = categories.map(category =>
        fetchRecipesForCategory(category, state.preferences, membershipStatus, lastFourWeeksRecipes)
      );

      const results = await Promise.all(fetchPromises);

      const chosenRecipesData = {};
      const explanationsData = {};

      categories.forEach((category, index) => {
        chosenRecipesData[category] = results[index].chosenRecipes || [];
        explanationsData[category] = results[index].explanation || '';
      });

      const processedRecipes = processRecipes(chosenRecipesData, state.allRecipes);

      setCurrentWeekGeneratedCombination(processedRecipes);
      setRecipesByCategory(processedRecipes);
      setExplanations(explanationsData);
    } catch (error) {
      console.error('Error fetching recipes with preferences:', error);
      setCurrentWeekGeneratedCombination(null);
      setRecipesByCategory({});
      setExplanations({});
    } finally {
      setLoading(false);
    }
  };

  // Fetches recipes for a specific category
  const fetchRecipesForCategory = async (category, preferences, membershipStatus, lastFourWeeksRecipes, existingRecipes = []) => {
    try {
      const response = await axios.get(`https://us-central1-the-weekly-meals.cloudfunctions.net/api/${category}`, {
        params: { preferences, membership: membershipStatus, lastFourWeeksRecipes, existingRecipes }
      });

      let responseData = response.data;

      if (typeof responseData === 'string') {
        try {
          responseData = JSON.parse(responseData);
        } catch (parseError) {
          console.warn('Failed to parse JSON:', parseError);
          return { chosenRecipes: [], explanation: '' };
        }
      }

      if (!responseData.chosenRecipes || !Array.isArray(responseData.chosenRecipes)) {
        console.error(`Invalid structure for ${category} response data:`, responseData);
        return { chosenRecipes: [], explanation: '' };
      }

      return responseData;
    } catch (error) {
      console.error(`Error fetching ${category} recipes:`, error);
      return { chosenRecipes: [], explanation: '' };
    }
  };

  // Processes the recipes and retrieves their full details
  const processRecipes = (chosenRecipesData, allRecipes) => {
    const processedRecipes = {};

    for (const category in chosenRecipesData) {
      const chosenRecipeNames = chosenRecipesData[category];
      processedRecipes[category] = [];

      if (Array.isArray(chosenRecipeNames)) {
        chosenRecipeNames.forEach(recipeName => {
          const recipeObject = allRecipes[category]?.find(recipe => recipe.name === recipeName);
          if (recipeObject) {
            processedRecipes[category].push(recipeObject);
          } else {
            console.warn(`Recipe '${recipeName}' not found in '${category}' recipes`);
          }
        });
      } else {
        console.error(`Unexpected structure for ${category} recipes:`, chosenRecipeNames);
      }
    }

    return processedRecipes;
  };

  // Generates the grocery list when the generatedRecipes state changes
  useEffect(() => {
    if (state.generatedRecipes) {
      generateGroceryListPerAisle();
    }
  }, [state.generatedRecipes]);

  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={<FoodPreferences preferences={state.preferences} setPreferences={setPreferences} getRecipesWithPreferences={getRecipesWithPreferences} />}
        />
        <Route
          path="/meal-planner/recipes"
          element={<RecipeSelection loading={loading} categoryLoading={categoryLoading} recipesByCategory={recipesByCategory} explanations={explanations} regenerateCategory={regenerateCategory} getRecipesWithPreferences={getRecipesWithPreferences} />}
        />
        <Route
          path="/meal-planner/sign-up"
          element={<AccountCreation />}
        />
        <Route
          path="/meal-planner/log-in"
          element={<Login />}
        />
        <Route
          path="/meal-planner/grocery-list"
          element={<GroceryList setGroceryListPerAisle={setGroceryListPerAisle} groceryListPerAisle={groceryListPerAisle} combination={state.generatedRecipes} weekID={weekID} />}
        />
        <Route
          path="/meal-planner/success"
          element={<Success />}
        />
      </Routes>
    </div>
  );
}
